import { Box, Dialog, Paper, Slide, Typography } from '@mui/material';
import { ArticleOutlined, CloseOutlined, HomeOutlined, LocalMallOutlined, MonetizationOnOutlined, PersonOutlined } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { adicionarPedidoImpresso, createNewTheme } from './functions/utils';

import React, { useRef } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import Home from './pages/Home';
import Cardapio from './pages/Cardapio';
import Pedidos from './pages/Pedidos';
import Perfil from './pages/Perfil';
import Cookies from 'js-cookie';
import axios from 'axios';
import SideMenu from './components/SideMenu';
import PedidosDelivery from './pages/PedidosDelivery';
import PedidosMesa from './pages/PedidosMesa';
import PedidosRetirada from './pages/PedidosRetirada';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import HistoricoPedidos from './pages/HistoricoPedidos';
import notificationSound from './functions/new-order.mp3';
import backgroundImage from "./imagens/bg-1.png";
import logoImage from "./imagens/logo.png";

import './components/Scrollbar.css';
import PulseFade from './components/SplashScreen';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppHome = () => {

  const [colorPrimary, setColorPrimary] = useState('');
  const [colorSecondary, setColorSecondary] = useState('');
  const [colorTertiary, setColorTertiary] = useState('');
  const [value, setValue] = useState('login');
  const [currentPage, setCurrentPage] = useState('');
  const [tipoLoja, setTipoLoja] = useState("restaurante");
  const [onlyMenu, setOnlyMenu] = useState(false);
  const [interaja, setInteraja] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [intervalPImprimir, setIntervalPImprimir] = useState(null);
  const [intervaloNotify, setIntervaloNotify] = useState(null);
  const [updateNot, setUpdateNot] = useState();
  const [updatePedidos, setUpdatePedidos] = useState(0);
  const [updatePedidos2, setUpdatePedidos2] = useState(0);
  const [updatePedidos3, setUpdatePedidos3] = useState(0);
  const [updatePedidos4, setUpdatePedidos4] = useState(0);
  const [diferencadias, setDiferencaDias] = useState(false);
  const [cobrar, setCobrar] = useState(false);
  const audioRef = React.useRef(null);

  const state = useLocation().state;
  let interagiuComPagina = false

  document.addEventListener("click", () => {
    interagiuComPagina = true;
  });

  const handleCloseItem = () => {
    setCobrar(false);
  };

  const handleInteract = () => {
    playNotification(1);
    getNovosPedidos();
    setInteraja(false);
  };

  const playNotification = (code) => {
    const audio = Cookies.get("Aud") !== "false";
    if (audio) {
      const sempedidos = Cookies.get("sPdd") ? Cookies.get("sPdd") : "false";
      if (sempedidos === "false") {
        console.log("SOUND CODE - ", code)
        if (audioRef.current) {
          audioRef.current.play();
        }
      }
    }
  };


  function verificarPedidosImprimir() {

    const urlBase = Cookies.get('UBs');
    const idEstabelecimentos = 1;
    const post_url = `${urlBase}/pedidos-imprimir.php?idestabelecimentos=${idEstabelecimentos}`;
    axios.get(post_url, JSON.stringify({ idestabelecimentos: idEstabelecimentos }))
      .then(response => {
        const impressora = Cookies.get("IAt") !== "false";
        if (impressora) {
          if (response.data !== null && response.data !== undefined) {
            response.data.forEach(item => {
              const ultimosPedidosImpressos = Cookies.get("LstPdI")
              if((ultimosPedidosImpressos && !ultimosPedidosImpressos.includes(item.idpedidos)) || (!ultimosPedidosImpressos)) {
                console.log("PEDIDOS IMPRESSOS - ", ultimosPedidosImpressos)
                adicionarPedidoImpresso(item.idpedidos)
                window.electronListener.imprimirPedido(urlBase, item.idpedidos);
              }
            });
          }
        } else {
          if (response.data !== null && response.data !== undefined) {
            response.data.forEach(item => {

              const post_url = `${urlBase}/atualizar-impressao.php`;
              axios.post(post_url, {
                idpedidos: item.idpedidos,
                status: "P"
              })
                .then(resp => {
                  const response = resp.data
                  console.log(response)
                })
                .catch(error => {
                  // console.log(error)
                });

            });
          }
        }
      })
      .catch(error => {
        console.error('NENHUM PEDIDO', post_url);
      });
  }

  const verificaUltimoPedido = () => {
    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get("Md");
    const post_url = urlBase + "/ultimo-pedido.php";
    const json = JSON.stringify({
      idestabelecimentos: idestabelecimentos,
    });
    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        const ultimopedido = Cookies.get('uTPd');

        if (ultimopedido != response.idpedidos) {
          if (interagiuComPagina) {
            playNotification(2);
            getNovosPedidos();
          } else {
            setInteraja(true)
          }
        }
        Cookies.set('uTPd', response.idpedidos, { expires: 7 });
      })
      .catch(error => {
        // console.log(error)
      });
  }

  const handleChange = (event, newValue, isSet) => {
    if (isSet) {
      Cookies.remove('LGt');
      console.log("-- atualizar notificacao --")
      const id = setInterval(verificaUltimoPedido, 10000);
      const pimprimir = setInterval(verificarPedidosImprimir, 5000);
      getNovosPedidos();
      setIntervalPImprimir(pimprimir);
      setIntervalId(id);
    }
    switch (newValue) {
      case 'entrega':
        setCurrentPage("Pedidos entrega");
        break;
      case 'mesa':
        setCurrentPage("Pedidos mesa");
        break;
      case 'retirada':
        setCurrentPage("Pedidos retirada");
        break;
      case 'dashboard':
        setCurrentPage("Dashboard");
        break;
      case 'cardapio':
        setCurrentPage("Novo pedido");
        break;
      case 'historico':
        setCurrentPage("Historico de pedidos");
        break;
      case 'login':
        clearInterval(intervalPImprimir);
        clearInterval(intervaloNotify);
        clearInterval(intervalId);
        Cookies.remove('dsEst');
        Cookies.remove('emEst');
        Cookies.remove('endEst');
        Cookies.remove('fMsP');
        Cookies.remove('hREst');
        Cookies.remove('iEst');
        Cookies.remove('nmEst');
        Cookies.remove('txEst');
        Cookies.remove('tEst');
        Cookies.remove('tsEst');
        Cookies.remove('UBs');
        Cookies.remove('isLoggedEst');
        // estabelecimento
        Cookies.remove('Md');
        Cookies.remove('Mn');
        Cookies.remove('UMs');
        Cookies.remove('eNbB');
        Cookies.remove('eNbM');
        Cookies.remove('eNbS');
        Cookies.remove('tpLj');
        Cookies.remove('vlMnm');
        Cookies.remove('uTPd');
        Cookies.set('LGt', true, { expires: 7 });
        setCurrentPage("Login");
        const randomParam = Math.random().toString(36).substring(7);
        window.location.href = `${window.location.pathname}?${randomParam}`;
        break;
      case "updateNot":
        interagiuComPagina = true
        // const id = setInterval(verificaUltimoPedido, 10000);
        getNovosPedidos();
        // setIntervalId(id);
        setUpdateNot(!updateNot)
        return;
      default:
        setCurrentPage("Home");
        break;
    }
    setValue(newValue);
  };

  useEffect(() => {

    Cookies.remove("msNv")
    Cookies.remove("dlNv")
    Cookies.remove("rtNv")
    if (state !== null) {
      if (state.page !== null) {
        setValue(state.page)
        state.page = null
      }
    }

    const onlyMenu = Cookies.get('eNbM') === "true";
    if (onlyMenu) {
      setOnlyMenu(onlyMenu)
    }

    const colorPrimaryC = Cookies.get('clPmr');
    if (!colorPrimaryC) {
      getColors()
      return
    }
    const colorSecondaryC = Cookies.get('clScr');
    const colorTertiaryC = Cookies.get('clTcr');

    setColorPrimary(colorPrimaryC)
    setColorSecondary(colorSecondaryC)
    setColorTertiary(colorTertiaryC)

    const urlBase = Cookies.get('UBs');
    if (urlBase) {
      const id = setInterval(verificaUltimoPedido, 10000);
      getNovosPedidos();
      setIntervalId(id);
      const pimprimir = setInterval(verificarPedidosImprimir, 5000);
      setIntervalPImprimir(pimprimir);
      // verificaVencimento();
    }
  }, []);

  const verificaVencimento = () => {
    const urlBase = Cookies.get('UBs');

    axios.get(`https://mely.online/api/verificapendencia.php?uri=${urlBase.replaceAll("/api", "")}`, {})
      .then(response => {
        setDiferencaDias(response.data.diferencadias)
        setCobrar(response.data.notifica)
      })
      .catch(error => console.error('Error:', error));
  }

  const getColors = () => {
    let urlBase = window.location.href;

    if (urlBase.includes("?")) {
      let arrUrl = urlBase.split("?");
      urlBase = arrUrl[0];
    }

    if (urlBase.includes("localhost")) {
      urlBase = "https://dedicado.mely.online/";
    }

    if (urlBase = "https://dedicado.mely.online/") {
      Cookies.set('clPmr', "204, 43, 2", { expires: 7 });
      Cookies.set('clScr', "126, 27, 2", { expires: 7 });
      Cookies.set('clTcr', "217, 119, 6", { expires: 7 });
      Cookies.set('tD', "Retirada e Entrega (45min - 55min)", { expires: 7 });
      setColorPrimary("204, 43, 2")
      setColorSecondary("126, 27, 2")
      setColorTertiary("217, 119, 6")
      return
    }

    axios.get(`${urlBase}/api/get-colors.php`, {})
      .then(response => {
        const arrColors = response.data.split(`\n`)
        const colorPrimaryC = arrColors[1].replaceAll("    --primary: ", "").replaceAll(";", "")
        const colorSecondaryC = arrColors[1].replaceAll("    --secondary: ", "").replaceAll(";", "")
        const colorTertiaryC = arrColors[1].replaceAll("    --tertiary: ", "").replaceAll(";", "")

        Cookies.set('clPmr', colorPrimaryC, { expires: 7 });
        Cookies.set('clScr', colorSecondaryC, { expires: 7 });
        Cookies.set('clTcr', colorTertiaryC, { expires: 7 });
        setColorPrimary(colorPrimaryC)
        setColorSecondary(colorSecondaryC)
        setColorTertiary(colorTertiaryC)
      })
      .catch(error => console.error('Error:', error));
  }

  const getNovosPedidos = () => {

    const horariosestabelecimento = Cookies.get('hREst');
    const horariosArray = horariosestabelecimento.split(",");
    const d = new Date();
    let diasemana
    if (d.getDay() == 0) {
      diasemana = horariosArray[6]
    } else {
      diasemana = horariosArray[d.getDay() - 1]
    }

    if (diasemana == "Fechado") {
      var unix1 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), "00", "00") / 1000);
      var unix2 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), "23", "59") / 1000);
    } else {
      var unix = Math.round(+new Date() / 1000);
      const horarios = diasemana.split(" às ");
      const horario1 = horarios[0].split(":");
      var unix1 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario1[0], horario1[1]) / 1000);
      const horario2 = horarios[1].split(":");
      var unix2 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario2[0], horario2[1]) / 1000);
    }

    if (unix2 <= unix1) {
      unix2 += 86400
    }

    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get("Md");

    const post_url = urlBase + "/novos-pedidos.php";
    const json = JSON.stringify({
      idestabelecimentos: idestabelecimentos,
      unix1: unix1,
      unix2: unix2
    });
    axios.post(post_url, json)
      .then(async resp => {
        const response = resp.data

        if (!response) {
          Cookies.remove('nTMs')
          Cookies.remove('nTDn')
          Cookies.remove('nTRn')
          return;
        }
        clearInterval(intervaloNotify);
        response.mesasNovos > 0 ? Cookies.set('nTMs', true, { expires: 7 }) : Cookies.remove('nTMs')
        response.deliveryNovos > 0 ? Cookies.set('nTDn', true, { expires: 7 }) : Cookies.remove('nTDn')
        response.retiradaNovos > 0 ? Cookies.set('nTRn', true, { expires: 7 }) : Cookies.remove('nTRn')

        if (response.mesasNovos > 0 || response.deliveryNovos > 0 || response.retiradaNovos > 0) {
          Cookies.remove("sPdd")
          const nt = Cookies.get("nTUpdt") === "true";

          const mesasNovas = Cookies.get("msNv") ? Cookies.get("msNv") : 0;
          const deliveryNovos = Cookies.get("dlNv") ? Cookies.get("dlNv") : 0;
          const retiradaNovos = Cookies.get("rtNv") ? Cookies.get("rtNv") : 0;
          console.log("COMPARER - ", mesasNovas, " - ", response.mesasNovos, " - ", mesasNovas != response.mesasNovos)
          console.log("COMPARER - ", deliveryNovos, " - ", response.deliveryNovos, " - ", deliveryNovos != response.deliveryNovos)
          console.log("COMPARER - ", retiradaNovos, " - ", response.retiradaNovos, " - ", retiradaNovos != response.retiradaNovos)
          if (!nt || mesasNovas != response.mesasNovos || deliveryNovos != response.deliveryNovos || retiradaNovos != response.retiradaNovos) {
            console.log("ATUALIZOU ", updatePedidos)
            Cookies.set('msNv', response.mesasNovos, { expires: 7 });
            Cookies.set('dlNv', response.deliveryNovos, { expires: 7 });
            Cookies.set('rtNv', response.retiradaNovos, { expires: 7 });
            setUpdatePedidos(Date.now())
            console.log("ATUALIZOU PARA ", Date.now() + 1)
            setUpdatePedidos2(Date.now() + 1)
            setUpdatePedidos3(Date.now() + 2)
            setUpdatePedidos4(Date.now() + 3)
          }
          Cookies.set('nTUpdt', true, { expires: 7 })
          // console.log(audio, " & ", interagiuComPagina)
          if (interagiuComPagina) {
            playNotification(3);
            const interval = setInterval(function () {
              playNotification(4)
            }, 30000);
            setIntervaloNotify(interval)
          } else {
            setInteraja(true)
          }
        } else {
          console.log("RM ATUALIZA PEDIDOS")
          Cookies.set('sPdd', true, { expires: 7 });
          Cookies.remove("msNv")
          Cookies.remove("dlNv")
          Cookies.remove("rtNv")
          Cookies.remove('nTUpdt')
          clearInterval(intervaloNotify);
        }
      })
      .catch(error => {
        console.log(error)
        console.log("ERRO CÓDIGO 12")
      });
  }

  const renderContent = () => {
    switch (value) {
      case 'entrega':
        return <PedidosDelivery onChange={handleChange} update={updatePedidos} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'mesa':
        return <PedidosMesa onChange={handleChange} update={updatePedidos2} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'retirada':
        return <PedidosRetirada onChange={handleChange} update={updatePedidos3} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'dashboard':
        return <Dashboard onChange={handleChange} update={updatePedidos4} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'cardapio':
        return <Cardapio onChange={handleChange} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'login':
        return <Login onChange={handleChange} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'historico':
        return <HistoricoPedidos onChange={handleChange} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      default:
        return <Login onChange={handleChange} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
    }
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <PulseFade />
      <div>
        {value !== "login" && (
          <SideMenu onChange={handleChange} update={updateNot} colorPrimary={colorPrimary} currentPage={currentPage} />
        )}
        {/* Renderiza o conteúdo da tela selecionada */}
        {renderContent()}
      </div>

      <Dialog
        open={cobrar === "S"}
        onClose={handleCloseItem}
        TransitionComponent={Transition}
        style={{ background: "rgba(0,0,0,0.7)" }}
      >

        <Typography style={{ position: "relative", top: "0px", background: "#f1f5f9", padding: 20, width: 600, display: "flex", alignItems: "center", fontSize: 22 }}>
          <strong>Atenção!</strong>
          <CloseOutlined variant='outlined' style={{ marginLeft: "auto", width: 30, cursor: "pointer" }} onClick={() => setCobrar(false)} />
        </Typography>

        <Typography style={{ position: "relative", top: "0px", background: "#f1f5f9", padding: "0px 20px 30px 20px", display: "flex", alignItems: "center", fontSize: 18 }}>
          <strong><b style={{ fontWeight: "normal" }}>Falta {diferencadias} {diferencadias > 1 ? <>dias</> : <>dia</>} para o vencimento do seu plano, efetue o pagamento para evitar a suspensão de seus serviços.</b><br /><br />*Se já efetuou o pagamento desconsidere essa notificação.</strong>

        </Typography>

      </Dialog>

      {interaja && (
        <Box
          onClick={handleInteract}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
          }}>

          <div style={{ height: 10 }}></div>

          <Typography style={{ textAlign: "center", fontSize: "22px", color: "white" }}>
            <img src={logoImage} /><br />
            <div style={{ height: 10 }}></div>
            O PDV está rodando em segundo plano, realize uma interação para voltar.
          </Typography>

          <div style={{ height: 30 }}></div>

          <div style={{ position: "fixed", bottom: 0, left: 10, fontSize: 16, color: "white" }}>
            © v2.0.1.0
          </div>

        </Box>
      )}
      <audio ref={audioRef}>
        <source src={notificationSound} type="audio/mpeg" />
      </audio>
    </ThemeProvider>
  );
};

export default AppHome;
