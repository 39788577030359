import { Container, Typography, IconButton, Button, AppBar, Toolbar } from '@mui/material';
import { AccessTimeOutlined, CloseOutlined, HomeOutlined, LocalPrintshopOutlined, PrintDisabledOutlined, PrintOutlined, VolumeOffOutlined, VolumeUpOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import * as React from 'react';

import MenuIcon from '@mui/icons-material/Menu';

import axios from 'axios';
import Cookies from 'js-cookie';
import LoadingComponent from '../components/LoadingComponent';
import Snackbar from '@mui/material/Snackbar';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';

function Finalizado() {

  const navigate = useNavigate();
  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [urlRedirect, setUrl] = useState("");
  const [isMesa, setIsMesa] = useState("");
  const [pedidos, setPedidos] = useState("");
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [audio, setAudio] = React.useState(true);
  const [impressora, setImpressora] = React.useState(true);
  const [tempoEntrega, setTempoEntrega] = useState("");

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const getChavePix = () => {
    return Cookies.get('cvP');
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleBack = () => {
    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      navigate(`/?nm=${btoa(numeroMesa)}`, { replace: true, state: { page: "home" } })
    } else {
      navigate("/", { replace: true, state: { page: "home" } })
    }
  };

  const handleAudio = (page) => {
    Cookies.set('Aud', !audio, { expires: 7 });
    setAudio(!audio)
  }

  const handleImpressora = (page) => {
    Cookies.set('IAt', !impressora, { expires: 7 });
    setImpressora(!impressora)
  }

  const handleInicio = () => {
    navigate("/", { replace: true, state: { page: "pedidos" } })
  };

  const handleCopy = async () => {
    try {
      const chavePix = Cookies.get('cvP');
      await navigator.clipboard.writeText(chavePix);
      handleSnack("Chave PIX copiada com sucesso.")
    } catch (err) {
      handleSnack("Não foi possivel copiar a chave PIX")
    }
  };

  useEffect(() => {
    handleOpenLoading();

    const audio = Cookies.get("Aud") !== "false";
    const impressora = Cookies.get("IAt") !== "false";
    const timeDelivery = Cookies.get('tD');
    const arrTime = timeDelivery.split("(")
    setTempoEntrega(arrTime[1].replaceAll(")", ""))
    setAudio(audio)
    setImpressora(impressora)

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;

    if (colorPrimary) {
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }

    // const telefoneCliente = Cookies.get('tUsr');
    // setUrl(`https://wa.me//55${telefoneCliente.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "")}?text=`);
    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      setIsMesa(true);
    }
    getPedidos(state.pedido);
  }, []);

  const getPedidos = (idp) => {
    const urlBase = Cookies.get('UBs');
    axios.get(`${urlBase}/pedido.php?idpedidos=${idp}`)
      .then(response => {
        if (response.data) {
          setPedidos(response.data);
          handleCloseLoading();
        }
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
        />

        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <Typography style={{ color: "#fff", fontSize: "20px" }}>Pedido finalizado</Typography>
            <Typography style={{ position: "absolute", right: 320, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "5px 10px 5px 8px", color: "#475569" }}>
              <AccessTimeOutlined />
              <Typography style={{ marginLeft: "10px", textAlign: "left", lineHeight: "1.2" }}>
                <strong>Tempo de entrega</strong>
                <br />
                <strong style={{ fontSize: "14px", color: "#0284c7" }}>{tempoEntrega}</strong>
              </Typography>
            </Typography>

            <Typography style={{ position: "absolute", right: 165, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "5px 10px 5px 8px", color: "#475569", cursor: "pointer" }} onClick={handleImpressora}>
              {impressora ? (
                <PrintOutlined />
              ) : (
                <PrintDisabledOutlined />
              )}
              <Typography style={{ marginLeft: "10px", textAlign: "left", lineHeight: "1.2" }}>
                <strong>Impressora</strong>
                <br />
                {impressora ? (
                  <strong style={{ fontSize: "14px", color: "#16a34a" }}>Ativa</strong>
                ) : (
                  <strong style={{ fontSize: "14px", color: "#FF5733" }}>Inativa</strong>
                )}
              </Typography>
            </Typography>

            <Typography style={{ position: "absolute", right: 90, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "7px 10px 7px 10px", color: "#475569", cursor: "pointer" }} onClick={handleAudio}>
              {audio ? (
                <VolumeUpOutlined style={{ fontSize: "34px" }} />
              ) : (
                <VolumeOffOutlined style={{ fontSize: "34px" }} />
              )}
            </Typography>

            <Typography style={{ position: "absolute", right: 16, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "7px 10px 7px 10px", color: "#475569", cursor: "pointer" }} onClick={handleInicio}>
              <HomeOutlined style={{ fontSize: "34px" }} />
            </Typography>

          </Toolbar>
        </AppBar>

        <div style={{ height: "70px" }}></div>
        {pedidos && (
          <Typography style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", margin: "0" }}>
            <Typography style={{ textAlign: "center", fontSize: "14px", color: "#999", minWidth: "300px" }}>

              <div style={{ height: "10px" }}></div>

              <img src="https://dedicado.mely.online/imagens/smiling-face.png" style={{ width: "90px" }} />

              <div style={{ height: "10px" }}></div>

              <Typography style={{ textAlign: "center", fontSize: "24px", color: "#1B1B1B", minWidth: "300px" }}>
                Pedido #{pedidos[0].data} criado!
              </Typography>
              <>
                O pedido foi realizado com sucesso!
              </>
              {pedidos[0].formapagamento === "PIX" && (
                <>
                  <br /><br />
                  Chave PIX: <b>{getChavePix()}</b>
                </>
              )}
              <div style={{ height: "20px" }}></div>
              {pedidos[0].formapagamento === "PIX" && (
                <>
                  <Button variant="contained" fullWidth size="large" style={{ fontWeight: "bold", textTransform: "capitalize", color: "#fff" }} onClick={handleCopy} target="_blank">Copiar chave PIX</Button>
                  <br /><br />
                </>
              )}
              <div style={{ height: "20px" }}></div>
              <Button fullWidth variant="contained" size="large" style={{ textTransform: "capitalize", fontWeight: "700", color: "#fff" }} onClick={handleInicio}>
                Voltar ao inicio
              </Button>
            </Typography>
          </Typography>
        )}

        <div style={{ height: "100px" }}></div>

      </Container>
    </ThemeProvider>
  );
}

export default Finalizado;