import { Container, AppBar, Toolbar, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Dialog, Slide, Radio, Divider, Button, Skeleton, Fab, InputAdornment, IconButton } from '@mui/material';
import { AddOutlined, ArrowBackOutlined, Check, CheckBox, CloseOutlined, EventAvailableOutlined, Facebook, Favorite, FavoriteBorderOutlined, Instagram, LinkOutlined, LocalOfferOutlined, PrintOutlined, QueryBuilderOutlined, RemoveOutlined, SearchOutlined, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { toReal, traduzirMes } from '../functions/utils';
import * as React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Cookies from 'js-cookie';
import LoadingComponent from '../components/LoadingComponent';
import Snackbar from '@mui/material/Snackbar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PedidosRetirada({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const { windows } = props;
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [open, setOpen] = useState(false);
  const [itemsNovos, setItemsNovos] = useState();
  const [itemsPreparo, setItemsPreparo] = useState();
  const [itemsEntrega, setItemsEntrega] = useState();
  const [items, setItems] = useState();
  const [pedidoSelecionado, setPedidoSelecionado] = useState("");
  const [pedidosRealizados, setPedidosRealizados] = useState("");
  const [tipoTaxa, setTipoTaxa] = useState("p");
  const [tipoDesconto, setTipoDesconto] = useState("p");
  const [taxa, setTaxas] = useState("");
  const [desconto, setDesconto] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [valorTotal, setValorTotal] = useState("");
  const [valorDescontos, setValorDescontos] = useState("");
  const [valorTaxas, setValorTaxas] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [itemShare, setItemShare] = useState("");
  const [isPizza, setIsPizza] = useState(false);
  const [segundoSabor, setSegundoSabor] = useState(false);
  const [tamanhoPizza, setTamanhoPizza] = useState("");
  const [saboresPizza, setSaboresPizza] = useState("");
  const [openModalItem, setOpenModalItem] = useState(false);
  const [showImgItem, setShowImgItem] = useState(false);
  const [segment, setSegment] = useState("Todos");
  const [comentario, setComentario] = useState('');
  const [qtdItemsAdd, setQtdItemsAdd] = useState(1);
  const [nota, setNota] = useState("");
  const [pesquisar, setPesquisar] = useState("");
  const [itemSelected, setItemSelected] = useState([]);
  const [opcionaisSelected, setOpcionaisSelected] = useState({});
  const [adicionaisSelected, setAdicionaisSelected] = useState({});
  const [limiteOpcionaisSelected, setLimiteOpcionaisSelected] = useState({});
  const [carrinho, setCarrinho] = useState([]);
  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");
  const [itemsOrigim, setItemsOrigim] = useState([]);
  const [destaques, setDestaque] = useState(null);
  const [urlBase, setUrlBase] = useState("");
  const [nome, setNome] = useState("");
  const [tempoEntrega, setTempoEntrega] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [valorTotalItem, setValorTotalItem] = useState(0);
  const [valorMinimo, setValorMinimo] = useState(false);
  const [statusEstabelecimento, setStatusEstabelecimento] = useState("");
  const [favoritos, setFavoritos] = useState(null);
  const [enableForBuy, setEnabledForBuy] = useState(false);
  const [textColor, setTextColor] = useState('#FFF');

  const navigate = useNavigate();

  const container = windows !== undefined ? () => windows().document.body : undefined;

  const handleCloseItem = () => {
    getPedidos();
    setOpenModalItem(false);
  };

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleNovoPedido = (title) => {
    onChange("", "cardapio");
    Cookies.set('tE', 1, { expires: 7 });
    Cookies.set('bCkPg', "retirada", { expires: 7 });
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  useEffect(() => {
    getPedidos();
  }, [update]);

  const getPedidos = () => {
    const idestabelecimento = Cookies.get("Md");
    const urlbase = Cookies.get("UBs");
    setUrlBase(urlbase);
    axios.post(`${urlbase}/pedidos-s.php`, {
      idestabelecimentos: idestabelecimento,
      isretirada: true,
      ismesa: false
    })
      .then(response => {
        response = response.data
        if (!response) {
          setItems([]);
          setItemsNovos([]);
          setItemsPreparo([]);
          setItemsEntrega([]);
          return
        }
        setItems(response);
        setItemsNovos(response.filter(item => item.status === 'R'));
        setItemsPreparo(response.filter(item => item.status === 'P'));
        setItemsEntrega(response.filter(item => item.status === 'E'));
        (response.filter(item => item.status === 'R').length > 0) ? Cookies.set('nTRn', true, { expires: 7 }) : Cookies.remove('nTRn')
        onChange("", "updateNot")
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  };

  const handleAtualizarPedido = (event, produto, status) => {
    event.stopPropagation();
    let arrHora
    const timestampAtual = Math.floor(new Date().getTime() / 1000);
    let demaishoras = produto.demaishoras
    demaishoras ? arrHora = demaishoras.split(",") : arrHora = (",,").split(",");
    switch (status) {
      case "P":
        arrHora[0] = timestampAtual;
        break;
      case "E":
        arrHora[1] = timestampAtual;
        break;
      case "F":
        arrHora[2] = timestampAtual;
        break;
    }
    demaishoras = (`${arrHora[0]},${arrHora[1]},${arrHora[2]}`).replaceAll("undefined", "")
    const post_url = urlBase + "/editar-pedido.php";
    const json = JSON.stringify({
      idpedidos: produto.idpedidos,
      status: status,
      demaishoras: demaishoras
    });

    axios.post(post_url, json)
      .then(resp => {
        console.log(resp.data)
        getPedidos();
      })
  }

  const handlePrint = () => {
    window.electronListener.imprimirPedidoManual(urlBase, pedidoSelecionado.nomecliente.includes("--") ? pedidoSelecionado.nomecliente : pedidoSelecionado.idpedidos);
  };

  const handleItem = (produto) => {
    const post_url = urlBase + "/pedido.php?idpedidos=" + produto.idpedidos;

    axios.post(post_url)
      .then(resp => {
        const response = resp.data
        console.log(response)
        setPedidoSelecionado(response[0])
        setFormaPagamento(response[0].formapagamento.toUpperCase())
        let valorTotal = 0
        for (const dados of response) {
          valorTotal += parseFloat(dados.valor.replaceAll(".", "").replaceAll(",", "."))
        }
        let tservico, tdesconto, vservico, vdesconto
        if (response[0].servico) {
          const arr = response[0].servico.split("--")
          tservico = arr[0]
          vservico = arr[1]
        } else {
          tservico = "p"
          vservico = "0"
        }
        if (response[0].desconto) {
          const arr = response[0].desconto.split("--")
          tdesconto = arr[0]
          vdesconto = arr[1]
        } else {
          tdesconto = "p"
          vdesconto = "0"
        }
        setTipoTaxa(tservico)
        setTipoDesconto(tdesconto)
        setTaxas(vservico)
        setDesconto(vdesconto)
        updateTaxaDesconto(tservico, tdesconto, vservico, vdesconto, toReal(valorTotal))

        setSubTotal(toReal(valorTotal))
      })
    setOpenModalItem(true)
  };

  const handlePesquisar = (event) => {
    setItemsNovos(items.filter(item => item.status === 'R' && (item.data.toLowerCase().includes(event.target.value.toLowerCase()) || item.nomecliente.toLowerCase().includes(event.target.value.toLowerCase()) || item.endereco.toLowerCase().includes(event.target.value.toLowerCase()))))
    setItemsPreparo(items.filter(item => item.status === 'P' && (item.data.toLowerCase().includes(event.target.value.toLowerCase()) || item.nomecliente.toLowerCase().includes(event.target.value.toLowerCase()) || item.endereco.toLowerCase().includes(event.target.value.toLowerCase()))))
    setItemsEntrega(items.filter(item => item.status === 'E' && (item.data.toLowerCase().includes(event.target.value.toLowerCase()) || item.nomecliente.toLowerCase().includes(event.target.value.toLowerCase()) || item.endereco.toLowerCase().includes(event.target.value.toLowerCase()))))
    setPesquisar(event.target.value);
  };

  const updateTaxaDesconto = (tipot, tipod, valort, valord, sbTotal) => {
    if (!sbTotal) {
      sbTotal = subTotal
    }
    let novoValorTotal = calculaValorTotalPedido(sbTotal, tipot, tipod, valort, valord);
    setValorTotal(novoValorTotal)
  }

  const calculaValorTotalPedido = (valorTotal, tipoTaxa, tipoDesconto, taxa, desconto) => {
    const opcaoTaxa = tipoTaxa
    const opcaoDesconto = tipoDesconto

    let valorTaxa = parseFloat(taxa.replaceAll(".", "").replaceAll(",", "."))
    let valorDesconto = parseFloat(desconto.replaceAll(".", "").replaceAll(",", "."))
    isNaN(valorTaxa) ? valorTaxa = 0 : console.log("-- DEBUG --")
    isNaN(valorDesconto) ? valorDesconto = 0 : console.log("-- DEBUG --")
    if (opcaoTaxa == "r") {
      setValorTaxas(valorTaxa !== 0 ? toReal(valorTaxa) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) + valorTaxa).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    } else {
      setValorTaxas(valorTaxa !== 0 ? toReal(parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) * (valorTaxa / 100)) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) * (1 + (valorTaxa / 100))).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
    if (opcaoDesconto == "r") {
      setValorDescontos(valorDesconto !== 0 ? toReal(valorDesconto) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) - valorDesconto).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    } else {
      setValorDescontos(valorDesconto !== 0 ? toReal(parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) * (valorDesconto / 100)) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) * (1 - (valorDesconto / 100))).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
    return valorTotal
  }

  const getPedidosRealizados = (detalhes) => {
    const detalhesArray = JSON.parse(atob(detalhes));
    let typographies = [];
    for (const detalhe of detalhesArray) {
      typographies.push(
        <Typography style={{ padding: "0px 20px 10px 20px", marginTop: "-8px", alignItems: "center", fontSize: "16px" }}>
          {detalhe.quantidade}x {detalhe.nome}<br />
          <strong style={{ color: "#94a3b8", fontWeight: "normal", fontSize: "14px" }}>Observações: {detalhe.observacoes ? detalhe.observacoes : "Não há observações"}</strong><br />
          {getAdicionais(detalhe.adicionais)}
          <strong style={{ color: "#333", fontSize: "16px" }}>
            Subtotal: R$ {detalhe.valorTotal}
          </strong>
          <div style={{ height: "10px" }}></div>
        </Typography>
      );
    }

    return typographies;
  };

  const getAdicionais = (adicionais) => {
    return adicionais.map((detalhe, index) => (
      <>
        <strong key={index} style={{ color: "#94a3b8", fontSize: "14px" }}>
          + {detalhe.nome ? detalhe.nome : detalhe.nomeOpcional} R$ {detalhe.valor ? detalhe.valor : detalhe.valorOpcional}
        </strong><br />
      </>
    ));
  };

  const getDatahora = (data) => {
    if (data) {
      let [dia, mes, a, restante] = data.split(" ");
      mes = traduzirMes(mes);
      return `${dia} ${mes} - ${restante}`;
    }
    return ""
  };

  return (
    <Container>


      <LoadingComponent open={loading} />

      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleClose}
        message={msgSnack}
        style={{ zIndex: "10001" }}
      />

      <Dialog
        open={openModalItem}
        onClose={handleCloseItem}
        TransitionComponent={Transition}
        style={{ background: "rgba(0,0,0,0.7)" }}
      >
        <Typography style={{ position: "relative", top: "0px", background: "#f1f5f9", padding: 20, display: "flex", alignItems: "center", width: "600px", fontSize: 22 }}>
          <strong style={{ color: "#333", cursor: "pointer" }}>
            #{pedidoSelecionado.data}
          </strong>
          <Chip variant='outlined' icon={<EventAvailableOutlined />} label={getDatahora(pedidoSelecionado.datahora)} style={{ marginLeft: "auto", paddingLeft: "10px", paddingRight: "10px", marginRight: 10 }} />
          <CloseOutlined variant='outlined' style={{ float: "right", width: 30, cursor: "pointer" }} onClick={() => handleCloseItem()} />
        </Typography>
        <Divider />
        <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px" }}>
          <strong style={{ color: "#333" }}>Dados do cliente</strong>
        </Typography>
        <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px", marginTop: "-18px" }}>
          <strong style={{ color: "#333", fontWeight: "normal" }}>{pedidoSelecionado.nomecliente}</strong>
        </Typography>
        <Divider style={{ marginLeft: 10, marginRight: 10 }} />
        <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px" }}>
          <strong style={{ color: "#333" }}>Detalhes do pedido</strong>
        </Typography>
        {pedidoSelecionado && (
          <>{getPedidosRealizados(pedidoSelecionado.detalhes)}</>
        )}
        <Divider style={{ marginLeft: 10, marginRight: 10, marginTop: "-10px" }} />
        <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px" }}>
          <strong style={{ color: "#333" }}>Detalhes do pagamento</strong>
        </Typography>
        <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px", marginTop: "-18px" }}>
          <strong style={{ color: "#333", fontWeight: "normal" }}>{formaPagamento}</strong>
        </Typography>

        <Divider style={{ marginLeft: 10, marginRight: 10 }} />

        <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px" }}>
          <strong style={{ color: "#333" }}>Subtotal</strong>
          <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {subTotal}</strong>
        </Typography>
        
        {pedidoSelecionado.taxa && parseFloat(pedidoSelecionado.taxa.replaceAll(".", "").replaceAll(",", ".")) && (
          <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px", marginTop: "-15px" }}>
            <strong style={{ color: "#333" }}>Taxa de entrega</strong>
            <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {pedidoSelecionado.taxa}</strong>
          </Typography>
        )}

        <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px", marginTop: "-15px" }}>
          <strong style={{ color: "#333" }}>Taxa de serviço</strong>
          <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {valorTaxas}</strong>
        </Typography>
        <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px", marginTop: "-15px" }}>
          <strong style={{ color: "#333" }}>Desconto</strong>
          <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {valorDescontos}</strong>
        </Typography>
        <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px", marginTop: "-15px" }}>
          <strong style={{ color: "#333" }}>Valor total</strong>
          <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {valorTotal}</strong>
        </Typography>

        <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px" }}>
          <Button disableElevation startIcon={<PrintOutlined />} variant="contained" style={{ backgroundColor: "#FF5733", width: "100%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize", marginRight: "10px" }} onClick={handlePrint}>Imprimir</Button>
        </Typography>
      </Dialog>

      <Typography style={{ fontWeight: "bold", fontSize: "22px", position: "absolute", left: 16, top: 80 }}>Meus pedidos para retirada</Typography>
      <TextField id="outlined-basic" label="Buscar pedido" InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined />
          </InputAdornment>
        ),
      }} variant="outlined" style={{ position: "absolute", left: 16, top: 130 }}
        value={pesquisar}
        onChange={handlePesquisar} />

      <Button startIcon={<AddOutlined />} variant="contained" size="large" style={{ position: "absolute", right: 16, top: 130, height: "56px", fontWeight: "bold", textTransform: "capitalize", color: "#fff" }} onClick={handleNovoPedido}>Novo pedido</Button>

      <div style={{ display: 'flex', position: "absolute", left: 0, right: 0, bottom: 0, top: 200 }}>
        <div style={{ flex: 1, backgroundColor: '#FF5733', height: '100vh', border: "solid white 16px", borderRightWidth: "8px", overflowY: "auto" }}>
          <Typography style={{ color: "white", padding: "10px", fontSize: "18px", fontWeight: "bold" }}>
            Pedidos novos
          </Typography>
          <div style={{ height: "20px" }}></div>

          {itemsNovos ? (
            <>
              {itemsNovos.map((produto) => (
                <>
                  <Card style={{ width: "95%", marginLeft: "2.5%", cursor: "pointer" }} onClick={() => handleItem(produto)}>
                    <Typography style={{ position: "relative", top: "0px", background: "#f1f5f9", padding: "10px 20px 10px 20px", display: "flex", alignItems: "center" }}>
                      <strong style={{ color: "#333" }}>#{produto.data}</strong>
                      <Chip variant='outlined' icon={<EventAvailableOutlined />} label={getDatahora(produto.datahora)} style={{ marginLeft: "auto", paddingLeft: "10px", paddingRight: "10px" }} />
                    </Typography>
                    <Divider />
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px" }}>
                      <strong style={{ color: "#333" }}>Dados do cliente</strong>
                      <strong style={{ color: "#333", marginLeft: "auto" }}>Telefone</strong>
                    </Typography>
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "12px", marginTop: "-18px" }}>
                      <strong style={{ color: "#333", fontWeight: "normal" }}>{produto.nomecliente}</strong>
                      <strong style={{ color: "#333", fontWeight: "normal", marginLeft: "auto" }}>{produto.telefonecliente ? produto.telefonecliente : "Não informado"}</strong>
                    </Typography>
                    <Divider style={{ marginLeft: 10, marginRight: 10 }} />
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px", marginTop: "-5px" }}>
                      <strong style={{ color: "#333" }}>Dados da entrega</strong>
                    </Typography>
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "13px", marginTop: "-18px" }}>
                      <strong style={{ color: "#333", fontWeight: "normal" }}>{produto.endereco}</strong>
                    </Typography>
                    <Divider style={{ marginLeft: 10, marginRight: 10 }} />
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px" }}>
                      <strong style={{ color: "#333" }}>Total</strong>
                      <strong style={{ marginLeft: "auto", paddingLeft: "10px", paddingRight: "10px" }} >R$ {produto.valor}</strong>
                    </Typography>

                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px" }}>
                      <Button disableElevation startIcon={<Check />} variant="contained" style={{ backgroundColor: "#4CAF50", width: "95%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize", marginRight: "2.5%" }} onClick={(event) => handleAtualizarPedido(event, produto, "P")}>Aceitar pedido</Button>
                      <IconButton style={{ background: "tomato", color: "white", marginLeft: "auto", borderRadius: "5px" }} onClick={(event) => handleAtualizarPedido(event, produto, "C")}>
                        <CloseOutlined />
                      </IconButton>
                    </Typography>
                  </Card>
                  <div style={{ height: "12px" }}></div>
                </>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
        <div style={{ flex: 1, backgroundColor: '#FFC300', height: '100vh', border: "solid white 16px", borderLeftWidth: "8px", borderRightWidth: "8px", overflowY: "auto" }}>
          <Typography style={{ color: "white", padding: "10px", fontSize: "18px", fontWeight: "bold" }}>
            Em preparo
          </Typography>

          <div style={{ height: "20px" }}></div>

          {itemsPreparo ? (
            <>
              {itemsPreparo.map((produto) => (
                <>
                  <Card style={{ width: "95%", marginLeft: "2.5%", cursor: "pointer" }} onClick={() => handleItem(produto)}>
                    <Typography style={{ position: "relative", top: "0px", background: "#f1f5f9", padding: "10px 20px 10px 20px", display: "flex", alignItems: "center" }}>
                      <strong style={{ color: "#333" }}>#{produto.data}</strong>
                      <Chip variant='outlined' icon={<EventAvailableOutlined />} label={getDatahora(produto.datahora)} style={{ marginLeft: "auto", paddingLeft: "10px", paddingRight: "10px" }} />
                    </Typography>
                    <Divider />
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px" }}>
                      <strong style={{ color: "#333" }}>Dados do cliente</strong>
                      <strong style={{ color: "#333", marginLeft: "auto" }}>Telefone</strong>
                    </Typography>
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "12px", marginTop: "-18px" }}>
                      <strong style={{ color: "#333", fontWeight: "normal" }}>{produto.nomecliente}</strong>
                      <strong style={{ color: "#333", fontWeight: "normal", marginLeft: "auto" }}>{produto.telefonecliente ? produto.telefonecliente : "Não informado"}</strong>
                    </Typography>
                    <Divider style={{ marginLeft: 10, marginRight: 10 }} />
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px", marginTop: "-5px" }}>
                      <strong style={{ color: "#333" }}>Dados da entrega</strong>
                    </Typography>
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "13px", marginTop: "-18px" }}>
                      <strong style={{ color: "#333", fontWeight: "normal" }}>{produto.endereco}</strong>
                    </Typography>
                    <Divider style={{ marginLeft: 10, marginRight: 10 }} />
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px" }}>
                      <strong style={{ color: "#333" }}>Total</strong>
                      <strong style={{ marginLeft: "auto", paddingLeft: "10px", paddingRight: "10px" }} >R$ {produto.valor}</strong>
                    </Typography>

                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px" }}>
                      <IconButton style={{ background: "#0284c7", color: "white", borderRadius: "5px" }} onClick={(event) => handleAtualizarPedido(event, produto, "R")}>
                        <ArrowBackOutlined />
                      </IconButton>
                      <Button disableElevation variant="contained" style={{ marginLeft: "2%", backgroundColor: "#4CAF50", width: "90%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize" }} onClick={(event) => handleAtualizarPedido(event, produto, "E")}>Avançar pedido</Button>
                    </Typography>
                  </Card>
                  <div style={{ height: "12px" }}></div>
                </>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
        <div style={{ flex: 1, backgroundColor: '#4CAF50', height: '100vh', border: "solid white 16px", borderLeftWidth: "8px", overflowY: "auto" }}>
          <Typography style={{ color: "white", padding: "10px", fontSize: "18px", fontWeight: "bold" }}>
            Aguardando retirada
          </Typography>

          <div style={{ height: "20px" }}></div>

          {itemsEntrega ? (
            <>
              {itemsEntrega.map((produto) => (
                <>
                  <Card style={{ width: "95%", marginLeft: "2.5%", cursor: "pointer" }} onClick={() => handleItem(produto)}>
                    <Typography style={{ position: "relative", top: "0px", background: "#f1f5f9", padding: "10px 20px 10px 20px", display: "flex", alignItems: "center" }}>
                      <strong style={{ color: "#333" }}>#{produto.data}</strong>
                      <Chip variant='outlined' icon={<EventAvailableOutlined />} label={getDatahora(produto.datahora)} style={{ marginLeft: "auto", paddingLeft: "10px", paddingRight: "10px" }} />
                    </Typography>
                    <Divider />
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px" }}>
                      <strong style={{ color: "#333" }}>Dados do cliente</strong>
                      <strong style={{ color: "#333", marginLeft: "auto" }}>Telefone</strong>
                    </Typography>
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "12px", marginTop: "-18px" }}>
                      <strong style={{ color: "#333", fontWeight: "normal" }}>{produto.nomecliente}</strong>
                      <strong style={{ color: "#333", fontWeight: "normal", marginLeft: "auto" }}>{produto.telefonecliente ? produto.telefonecliente : "Não informado"}</strong>
                    </Typography>
                    <Divider style={{ marginLeft: 10, marginRight: 10 }} />
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px", marginTop: "-5px" }}>
                      <strong style={{ color: "#333" }}>Dados da entrega</strong>
                    </Typography>
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "13px", marginTop: "-18px" }}>
                      <strong style={{ color: "#333", fontWeight: "normal" }}>{produto.endereco}</strong>
                    </Typography>
                    <Divider style={{ marginLeft: 10, marginRight: 10 }} />
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px" }}>
                      <strong style={{ color: "#333" }}>Total</strong>
                      <strong style={{ marginLeft: "auto", paddingLeft: "10px", paddingRight: "10px" }} >R$ {produto.valor}</strong>
                    </Typography>

                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px" }}>
                      <IconButton style={{ background: "#0284c7", color: "white", borderRadius: "5px" }} onClick={(event) => handleAtualizarPedido(event, produto, "P")}>
                        <ArrowBackOutlined />
                      </IconButton>
                      <Button disableElevation variant="contained" style={{ marginLeft: "2%", backgroundColor: "#4CAF50", width: "90%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize" }} onClick={(event) => handleAtualizarPedido(event, produto, "F")}>Finalizar pedido</Button>
                    </Typography>
                  </Card>
                  <div style={{ height: "12px" }}></div>
                </>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div style={{ height: "150px" }}></div>

    </Container>
  );
}

export default PedidosRetirada;