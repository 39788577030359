import { Container, AppBar, Toolbar, Typography, IconButton, Button, Grid } from '@mui/material';
import { AccessTimeOutlined, AddOutlined, ArrowBackOutlined, DeleteOutline, HomeOutlined, LocalPrintshopOutlined, PrintDisabledOutlined, PrintOutlined, RemoveOutlined, VolumeOffOutlined, VolumeUpOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { toReal, createNewTheme } from '../functions/utils';
import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Cookies from 'js-cookie';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';

function Carrinho() {

  const state = useLocation().state;
  const page = state ? state.page : 'home';
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [audio, setAudio] = React.useState(true);
  const [impressora, setImpressora] = React.useState(true);
  const [tempoEntrega, setTempoEntrega] = useState("");
  const [carrinho, setCarrinho] = useState([]);
  const [urlBase, setUrlBase] = useState("");
  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/", { replace: true, state: { page: page } })
  };

  const handleAudio = (page) => {
    Cookies.set('Aud', !audio, { expires: 7 });
    setAudio(!audio)
  }

  const handleImpressora = (page) => {
    Cookies.set('IAt', !impressora, { expires: 7 });
    setImpressora(!impressora)
  }

  const handleInicio = () => {
    navigate("/", { replace: true, state: { page: "pedidos" } })
  };

  const handleClear = () => {
    Cookies.remove('crU');
    Cookies.remove('bCrt');
    Cookies.remove('vPt');
    navigate("/", { replace: true, state: { page: page } })
  };

  const handleEnderecos = () => {
    navigate("/enderecos", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
  };

  const handleAddQtd = (id) => {
    let bloquearCarrinho = Cookies.get('bCrt');
    if (bloquearCarrinho) {
      return;
    }

    for (const produto of carrinho) {
      if (produto.id === id) {
        produto.quantidade += 1
        produto.valorTotal = toReal(parseFloat(produto.valorComAdicionais.replaceAll(".", "").replaceAll(",", '.')) * produto.quantidade)
      }
    }
    Cookies.set('crU', btoa(JSON.stringify(carrinho)), { expires: 7 });
    updateCart();
  };

  const handleRmQtd = (id) => {
    let count = 0
    for (const produto of carrinho) {
      if (produto.id === id) {
        if (produto.quantidade === 1) {
          carrinho.splice(count, 1);
          continue
        }
        produto.quantidade -= 1
        produto.valorTotal = toReal(parseFloat(produto.valorComAdicionais.replaceAll(".", "").replaceAll(",", '.')) * produto.quantidade)
      }
      count++
    }
    let bloquearCarrinho = Cookies.get('bCrt');
    if (bloquearCarrinho) {
      handleClear();
    }
    Cookies.set('crU', btoa(JSON.stringify(carrinho)), { expires: 7 });
    updateCart();
  };

  useEffect(() => {

    const audio = Cookies.get("Aud") !== "false";
    const impressora = Cookies.get("IAt") !== "false";
    const timeDelivery = Cookies.get('tD');
    const arrTime = timeDelivery.split("(")
    setTempoEntrega(arrTime[1].replaceAll(")", ""))
    setAudio(audio)
    setImpressora(impressora)

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;

    if (colorPrimary) {
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }

    const url = Cookies.get('UBs');
    setUrlBase(url);
    updateCart();

  }, []);

  const updateCart = () => {
    let currentCarrinho = Cookies.get('crU');
    if (currentCarrinho) {
      currentCarrinho = JSON.parse(atob(currentCarrinho))
      console.log(currentCarrinho)
      let valorTotal = 0
      setCarrinho(currentCarrinho)
      for (const produto of currentCarrinho) {
        valorTotal += parseFloat(produto.valorTotal.replaceAll(".", "").replaceAll(",", '.'))
      }
      let bloquearCarrinho = Cookies.get('bCrt');
      if (valorTotal === 0 && !bloquearCarrinho) {
        Cookies.remove('crU');
        handleBack()
      }
      Cookies.set('vlT', toReal(valorTotal), { expires: 7 });
      setValorTotalCarrinho(toReal(valorTotal))
    }
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <div style={{ padding: 16 }}>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            <Typography style={{ color: "#fff", fontSize: "20px" }}>Sacola</Typography>
            <Typography style={{ position: "absolute", right: 320, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "5px 10px 5px 8px", color: "#475569" }}>
              <AccessTimeOutlined />
              <Typography style={{ marginLeft: "10px", textAlign: "left", lineHeight: "1.2" }}>
                <strong>Tempo de entrega</strong>
                <br />
                <strong style={{ fontSize: "14px", color: "#0284c7" }}>{tempoEntrega}</strong>
              </Typography>
            </Typography>

            <Typography style={{ position: "absolute", right: 165, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "5px 10px 5px 8px", color: "#475569", cursor: "pointer" }} onClick={handleImpressora}>
              {impressora ? (
                <PrintOutlined />
              ) : (
                <PrintDisabledOutlined />
              )}
              <Typography style={{ marginLeft: "10px", textAlign: "left", lineHeight: "1.2" }}>
                <strong>Impressora</strong>
                <br />
                {impressora ? (
                  <strong style={{ fontSize: "14px", color: "#16a34a" }}>Ativa</strong>
                ) : (
                  <strong style={{ fontSize: "14px", color: "#FF5733" }}>Inativa</strong>
                )}
              </Typography>
            </Typography>

            <Typography style={{ position: "absolute", right: 90, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "7px 10px 7px 10px", color: "#475569", cursor: "pointer" }} onClick={handleAudio}>
              {audio ? (
                <VolumeUpOutlined style={{ fontSize: "34px" }} />
              ) : (
                <VolumeOffOutlined style={{ fontSize: "34px" }} />
              )}
            </Typography>

            <Typography style={{ position: "absolute", right: 16, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "7px 10px 7px 10px", color: "#475569", cursor: "pointer" }} onClick={handleInicio}>
              <HomeOutlined style={{ fontSize: "34px" }} />
            </Typography>
            <Button style={{ position: "absolute", right: 520, fontSize: "20px", textTransform: "capitalize", fontWeight: "normal", color: "white" }} variant='contained' color='secondary' onClick={handleClear}>Limpar</Button>
          </Toolbar>
        </AppBar>

        <div style={{ height: "80px" }}></div>

        <Button variant="text" style={{ textTransform: "capitalize", fontWeight: "700" }} startIcon={<AddOutlined />} onClick={handleBack}>
          Adicionar mais itens
        </Button>

        <div style={{ height: "8px" }}></div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {carrinho.map((produto, index) => (
                <>
                  {index % 2 === 0 && (
                    <>
                      <ListItem key={produto.id} alignItems="flex-start">
                        <ListItemAvatar>
                          <img src={`${urlBase.replaceAll("/api", "")}/imagens-produtos/${produto.id}_0.png`} style={{ borderRadius: "4px", height: "56px", width: "56px" }} onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                          }} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={produto.nome}
                          primaryTypographyProps={{ fontSize: "16px", color: "#1B1B1B", fontWeight: "bold", paddingLeft: "16px" }}
                          secondary={
                            <React.Fragment>
                              <Typography sx={{ fontSize: "14px", color: "#999", paddingLeft: "16px" }}>
                                {produto.descricao}
                                {(produto.adicionais).map((adicional) => (
                                  <>
                                    <b style={{ fontSize: "12px" }}><br />+ {adicional.quantidadeOpcional}x {adicional.nomeOpcional}</b>
                                  </>
                                ))}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <div style={{ backgroundColor: "white", padding: "16px", display: "flex", alignItems: "center" }}>
                        <Typography style={{ float: "left", fontSize: "16px", fontWeight: "bold", color: `rgba(${colorPrimary})` }}>
                          R$ {produto.valorTotal}
                        </Typography>

                        <div style={{ position: "absolute", right: "16px", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "131px", height: "40px", display: "flex", alignItems: "center" }}>
                          <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700" }}>{produto.quantidade}</p>
                          {produto.quantidade === 1 ? (
                            <DeleteOutline style={{ position: "absolute", left: "16px", color: "#464545" }} onClick={() => handleRmQtd(produto.id)} />
                          ) : (
                            <RemoveOutlined style={{ position: "absolute", left: "16px", color: "#464545" }} onClick={() => handleRmQtd(produto.id)} />
                          )}
                          <AddOutlined style={{ position: "absolute", right: "16px", color: "#464545" }} onClick={() => handleAddQtd(produto.id)} />
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {carrinho.map((produto, index) => (
                <>
                  {index % 2 !== 0 && (
                    <>
                      <ListItem key={produto.id} alignItems="flex-start">
                        <ListItemAvatar>
                          <img src={`${urlBase.replaceAll("/api", "")}/imagens-produtos/${produto.id}_0.png`} style={{ borderRadius: "4px", height: "56px", width: "56px" }} onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                          }} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={produto.nome}
                          primaryTypographyProps={{ fontSize: "16px", color: "#1B1B1B", fontWeight: "bold", paddingLeft: "16px" }}
                          secondary={
                            <React.Fragment>
                              <Typography sx={{ fontSize: "14px", color: "#999", paddingLeft: "16px" }}>
                                {produto.descricao}
                                {(produto.adicionais).map((adicional) => (
                                  <>
                                    <b style={{ fontSize: "12px" }}><br />+ {adicional.quantidadeOpcional}x {adicional.nomeOpcional}</b>
                                  </>
                                ))}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <div style={{ backgroundColor: "white", padding: "16px", display: "flex", alignItems: "center" }}>
                        <Typography style={{ float: "left", fontSize: "16px", fontWeight: "bold", color: `rgba(${colorPrimary})` }}>
                          R$ {produto.valorTotal}
                        </Typography>

                        <div style={{ position: "absolute", right: "16px", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "131px", height: "40px", display: "flex", alignItems: "center" }}>
                          <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700" }}>{produto.quantidade}</p>
                          {produto.quantidade === 1 ? (
                            <DeleteOutline style={{ position: "absolute", left: "16px", color: "#464545" }} onClick={() => handleRmQtd(produto.id)} />
                          ) : (
                            <RemoveOutlined style={{ position: "absolute", left: "16px", color: "#464545" }} onClick={() => handleRmQtd(produto.id)} />
                          )}
                          <AddOutlined style={{ position: "absolute", right: "16px", color: "#464545" }} onClick={() => handleAddQtd(produto.id)} />
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))}
            </List>
          </Grid>
        </Grid>

        <div style={{ borderTop: `solid 1px rgba(${colorPrimary})`, position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "16px 16px 16px 16px" }}>
          <Typography style={{ float: "left", fontSize: "14px", fontWeight: "bold", paddingLeft: "16px" }}>
            Total
          </Typography>
          <Typography style={{ float: "right", fontSize: "16px", fontWeight: "bold", color: `rgba(${colorPrimary})` }}>
            {valorTotalCarrinho === "0" ? (
              <>
                Resgate
              </>
            ) : (
              <>
                R$ {valorTotalCarrinho}
              </>
            )}
          </Typography>
          <div style={{ height: "35px" }}></div>
          <Button fullWidth variant="contained" size="large" style={{ textTransform: "capitalize", fontWeight: "700", color: "#fff" }} onClick={handleEnderecos}>
            Continuar
          </Button>
        </div>
        <div style={{ height: "200px" }}></div>

      </div>
    </ThemeProvider>
  );
}

export default Carrinho;