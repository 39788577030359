import { Container, AppBar, Toolbar, Typography, IconButton, Button, Box, Tabs, Tab, Card, CardHeader, FormControl, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemAvatar, ListItemText, Divider } from '@mui/material';
import { AccessTimeOutlined, ArrowBackOutlined, CardMembershipOutlined, CreditCardOutlined, HomeOutlined, LocalPrintshopOutlined, PaymentsOutlined, PixOutlined, PrintDisabledOutlined, PrintOutlined, TableBarOutlined, VolumeOffOutlined, VolumeUpOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CustomTabPanel, a11yProps, toReal, createNewTheme } from '../functions/utils';
import * as React from 'react';

import LinkIcon from '@mui/icons-material/Link';
import axios from 'axios';
import Cookies from 'js-cookie';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingComponent from '../components/LoadingComponent';
import Snackbar from '@mui/material/Snackbar';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';

function Finalizar() {

  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const page = state ? state.page : 'home';
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [audio, setAudio] = React.useState(true);
  const [impressora, setImpressora] = React.useState(true);
  const [messageSnack, setMessageSnack] = useState("");
  const [telefoneAvulso, setTelefoneAvulso] = useState("");
  const [nomeAvulso, setNomeAvulso] = useState("");
  const [taxaEntrega, setTaxaEntrega] = useState("");
  const [tipoEntrega, setTipoEntrega] = useState("");
  const [isMesa, setIsMesa] = useState(false);
  const [carrinho, setCarrinho] = useState([]);
  const [numeroMesa, setNumeroMesa] = useState(false);
  const [formaPagamento, setFormaPagamento] = useState("");
  const [enderecoEntrega, setEnderecoEntrega] = useState("");
  const [enderecoRetirada, setEnderecoRetirada] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [tempoEntrega, setTempoEntrega] = useState("");
  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [urlBase, setUrlBase] = useState("");

  const navigate = useNavigate();

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleBack = () => {
    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      navigate("/enderecos", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
    } else {
      navigate("/pagamentos", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
    }
  };

  const handleAudio = (page) => {
    Cookies.set('Aud', !audio, { expires: 7 });
    setAudio(!audio)
  }

  const handleImpressora = (page) => {
    Cookies.set('IAt', !impressora, { expires: 7 });
    setImpressora(!impressora)
  }

  const handleInicio = () => {
    navigate("/", { replace: true, state: { page: "pedidos" } })
  };

  const handleClose = () => {
    handleCloseLoading(false);
    setOpen(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnack = (title) => {
    setMessageSnack(title)
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleTelefone = (event) => {
    setTelefoneAvulso(event.target.value);
  };

  const handleNome = (event) => {
    setNomeAvulso(event.target.value);
  };

  const handleFinalizar = (isAvulso) => {

    handleOpenLoading();
    let detalhes = "", adicionaissrt = "", count = 0, mensagemWhats
    const opcaoEntrega = tipoEntrega === 1 ? `*Endereço para entrega:*
*Retirada*` : `*Opção de entrega:*
*${enderecoEntrega}*`

    const mainId = Cookies.get('Md');
    const mainName = Cookies.get('Mn');
    const currentCarrinho = Cookies.get('crU');
    const idusuario = Cookies.get('iUsr');
    const urlMessage = Cookies.get('UMs');
    let json
    for (const produto of JSON.parse(atob(currentCarrinho))) {
      let c = 0
      for (const adicional of produto.adicionais) {
        if (c == 0) {
          adicionaissrt += `✅ Adicionais:
`
        }
        adicionaissrt += adicional.categoriaOpcional + " " + adicional.nomeOpcional.toLowerCase() + ": + " + adicional.quantidadeOpcional + "x R$" + adicional.valorOpcional + `
`
        c++;
      }
      const subTotal = (parseFloat(produto.valorOriginal.replace(".", "").replace(",", ".")) * parseInt(produto.quantidade)).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
      detalhes = detalhes + "➡ " + produto.nome + `
Quantidade: ` + produto.quantidade + `
Valor Unitário: *R$ ` + produto.valorOriginal + `*
Subtotal: *R$ ` + subTotal + `*
` + adicionaissrt + "Observações no item: " + produto.observacoes + `
`
      count++
      adicionaissrt = ""
    }

    if (parseInt(idusuario) > 0 && !isMesa) {
      const nomecliente = Cookies.get('nmUsr');
      const telefone = Cookies.get('tUsr');

      const mensagem = getMessage(nomecliente, telefone, detalhes, formaPagamento, opcaoEntrega)
      mensagemWhats = getMessage(nomecliente, telefone, detalhes, formaPagamento, opcaoEntrega)
      mensagemWhats = mensagemWhats.replaceAll('$tipomensagem$', "📍 *PEDIDO NOVO*");
      mensagemWhats = mensagemWhats.replaceAll('$pedido$', `*Nome: ${nomecliente}*\n*Celular: ${telefone}*`);
      mensagemWhats = mensagemWhats.replaceAll('$faleconosco$', "");
      let bloquearCarrinho = Cookies.get('bCrt');
      json = {
        idestabelecimentos: mainId,
        nomeestabelecimento: mainName,
        idusuarios: "0",
        nomecliente: nomecliente,
        detalhes: currentCarrinho,
        taxa: taxaEntrega ? taxaEntrega : "",
        valor: bloquearCarrinho ? Cookies.get('vPt') : valorTotalCarrinho,
        formapagamento: formaPagamento.includes("dinheiro") ? `${Cookies.get('sT') ? `Dinheiro - Troco para R$ ${toReal(Cookies.get('sT'))}` : "Dinheiro - Sem troco"}` : formaPagamento.toUpperCase(),
        endereco: tipoEntrega === 1 ? "Retirada" : enderecoEntrega,
        telefone: telefone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", ""),
        numero: urlMessage.replaceAll("https://wa.me/", "").replaceAll("?text=", ""),
        mensagem: mensagem
      }
    } else {

      // let nomeAvulso = Cookies.get('nmUsr') ? Cookies.get('nmUsr') : "Ävulso";
      let nomeAvulso = "Avulso"
      const mensagem = getMessage(nomeAvulso, telefoneAvulso, detalhes, formaPagamento, opcaoEntrega, isMesa, numeroMesa)
      mensagemWhats = getMessage(nomeAvulso, telefoneAvulso, detalhes, formaPagamento, opcaoEntrega, isMesa, numeroMesa)
      mensagemWhats = mensagemWhats.replaceAll('$tipomensagem$', "📍 *PEDIDO NOVO*");
      mensagemWhats = mensagemWhats.replaceAll('$pedido$', `*Nome: ${nomeAvulso}*\n*Celular: ${telefoneAvulso}*`);
      mensagemWhats = mensagemWhats.replaceAll('$faleconosco$', "");
      handleClose();
      handleOpenLoading();

      let bloquearCarrinho = Cookies.get('bCrt');
      if (isMesa) {
        json = {
          idestabelecimentos: mainId,
          nomeestabelecimento: mainName,
          idusuarios: "0",
          nomecliente: `mesa--${numeroMesa}--${nomeAvulso}`,
          detalhes: currentCarrinho,
          taxa: "",
          valor: valorTotalCarrinho,
          formapagamento: "",
          endereco: "Retirada",
          telefone: telefoneAvulso,
          numero: urlMessage.replaceAll("https://wa.me/", "").replaceAll("?text=", ""),
          mensagem: mensagem
        }
      } else {
        json = {
          idestabelecimentos: mainId,
          nomeestabelecimento: mainName,
          idusuarios: "0",
          nomecliente: Cookies.get('nmUsr') ? Cookies.get('nmUsr') : nomeAvulso,
          detalhes: currentCarrinho,
          taxa: taxaEntrega ? taxaEntrega : "",
          valor: bloquearCarrinho ? Cookies.get('vPt') : valorTotalCarrinho,
          formapagamento: formaPagamento.includes("dinheiro") ? `${Cookies.get('sT') ? `Dinheiro - Troco para R$ ${toReal(Cookies.get('sT'))}` : "Dinheiro - Sem troco"}` : formaPagamento.toUpperCase(),
          endereco: tipoEntrega === 1 ? "Retirada" : enderecoEntrega,
          telefone: telefoneAvulso ? telefoneAvulso : Cookies.get('tUsr').replaceAll("(", "").replaceAll(") ", "").replaceAll("-", ""),
          numero: urlMessage.replaceAll("https://wa.me/", "").replaceAll("?text=", ""),
          mensagem: mensagem
        }
      }
    }

    const url = Cookies.get('UBs');
    let bloquearCarrinho = Cookies.get('bCrt');

    let endpoint = "cadastro-pedido.php";
    if (bloquearCarrinho) {
      endpoint = "cadastro-pedido-pontos.php"
    }
    console.log(json)
    axios.post(`${url}/${endpoint}`, json)
      .then(response => {
        response = response.data
        if (!response) {
          handleSnack("Não foi possivel confirmar seu pedido, verifique sua conexão e tente novamente.")
          return;
        }
        if (response.resposta === "pontos insuficientes") {
          handleSnack("Você não possui pontos suficientes para realizar o resgate!")
          return;
        }
        if (response.resposta === "sucesso") {
          mensagemWhats = mensagemWhats.replaceAll(`
`, "%0A")
          const urlWhats = `${urlMessage.replaceAll("?", "&").replaceAll("https://wa.me/", "whatsapp://send?phone=")}${mensagemWhats}`;
          // window.open(urlWhats, '_blank');
          // console.log(urlWhats)
          // console.log(urlMessage.replaceAll("?", "&").replaceAll("https://wa.me/", "whatsapp://send?phone="))
          let pontos = parseInt(Cookies.get('pUsr')) - parseInt(Cookies.get('vPt'));
          if (bloquearCarrinho) {
            Cookies.set('pUsr', pontos, { expires: 7 });
          }
          Cookies.remove('eEA');
          Cookies.remove('nmEA');
          Cookies.remove('iEA');
          Cookies.remove('txE');
          Cookies.remove('fmP');
          Cookies.remove('tE');
          Cookies.remove('vlT');
          Cookies.remove('sT');
          Cookies.remove('crU');
          Cookies.remove('bCrt');
          Cookies.remove('vPt');
          Cookies.remove('tUsr');
          Cookies.remove('nmUsr');
          navigate("/finalizado", { replace: true, state: { pedido: response.idpedido, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
          return;
        }
        handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.")
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  };

  const getMessage = (nome, telefone, detalhes, formapagamento, opcaoentrega, isMesa, numeroMesa) => {
    let detalhesAdicionais
    if (isMesa) {
      detalhesAdicionais = `Número da mesa: ${numeroMesa}`
    } else {
      detalhesAdicionais = `Forma de pagamento: ${formapagamento.toUpperCase()}
${Cookies.get('sT') ? `Troco para R$ ${toReal(Cookies.get('sT'))}` : "Sem troco"}
${opcaoentrega}`
    }

    return `$tipomensagem$

$pedido$
🛒 Os itens escolhidos são:

${detalhes}
Taxa de entrega: ${taxaEntrega ? `R$ ${taxaEntrega}` : "-"}
Valor total do pedido: R$ ${valorTotalCarrinho}
${detalhesAdicionais}

$faleconosco$`
  }

  const handleChange = (event, newValue) => {
    setTipoEntrega(newValue);
    setButtonEnabled(false);
    if (formaPagamento === "definir" && newValue !== 2) {
      setButtonEnabled(true);
      return;
    }
    if (newValue === 2) {
      setIsMesa(true)
    } else {
      setIsMesa(false)
    }
    if (newValue === 2 && !numeroMesa) {
      setButtonEnabled(true);
      return;
    }
    if (newValue === 0 && (!enderecoEntrega || enderecoEntrega === "-")) {
      setEnderecoEntrega("-");
      setTaxaEntrega("-");
      setButtonEnabled(true);
      return;
    }
    if (!taxaEntrega && newValue === 0) {
      handleSnack("Desculpe, o estabelecimento não trabalha com entregas no endereço indicado.");
      setButtonEnabled(true);
      return
    }
    if (taxaEntrega) {
      if (parseFloat(taxaEntrega.replaceAll(",", ".")) > 0 && newValue === 0) {
        setValorTotalCarrinho(toReal(parseFloat(Cookies.get('vlT').replaceAll(".", "").replaceAll(",", '.')) + parseFloat(taxaEntrega.replaceAll(".", "").replaceAll(",", '.'))))
      } else {
        setButtonEnabled(false);
        setValorTotalCarrinho(toReal(parseFloat(Cookies.get('vlT').replaceAll(".", "").replaceAll(",", '.'))))
      }
    }
    if (newValue === 1) {
      setButtonEnabled(false);
      setValorTotalCarrinho(toReal(parseFloat(Cookies.get('vlT').replaceAll(".", "").replaceAll(",", '.'))))
    }
  };

  const handleEndereco = (event) => {
    navigate("/enderecos", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
  };

  const handlePagamento = (event) => {
    let bloquearCarrinho = Cookies.get('bCrt');
    if (bloquearCarrinho) {
      handleSnack("Você não pode alterar a forma de pagamento enquanto faz um resgate!")
      return;
    }
    navigate("/pagamentos", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
  };

  const updateCart = () => {
    let currentCarrinho = Cookies.get('crU');
    if (currentCarrinho) {
      currentCarrinho = JSON.parse(atob(currentCarrinho))
      let valorTotal = 0
      setCarrinho(currentCarrinho)
      for (const produto of currentCarrinho) {
        valorTotal += parseFloat(produto.valorTotal.replaceAll(".", "").replaceAll(",", '.'))
      }
      let bloquearCarrinho = Cookies.get('bCrt');
      if (valorTotal === 0 && !bloquearCarrinho) {
        Cookies.remove('crU');
        handleBack()
      }
      Cookies.set('vlT', toReal(valorTotal), { expires: 7 });
      setValorTotalCarrinho(toReal(valorTotal))
    }
  }

  useEffect(() => {
    handleOpenLoading();

    const audio = Cookies.get("Aud") !== "false";
    const impressora = Cookies.get("IAt") !== "false";
    setAudio(audio)
    setImpressora(impressora)

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;

    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      setNumeroMesa(numeroMesa)
      if (Cookies.get('tE') == "2") {
        setIsMesa(true)
        updateCart();
      }
    }

    if (colorPrimary) {
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }

    const entrega = Cookies.get('tE');
    setTipoEntrega(parseInt(entrega))
    const endereco = Cookies.get('eEA');
    setEnderecoEntrega(endereco)
    const pagamento = Cookies.get('fmP');
    setFormaPagamento(pagamento)
    const retirada = Cookies.get('eR');
    setEnderecoRetirada(retirada)
    const timeDelivery = Cookies.get('tD');
    const arrTime = timeDelivery.split("(")
    setTempoEntrega(arrTime[1].replaceAll(")", ""))
    const taxaEntrega = Cookies.get('txE');
    taxaEntrega === "0,00" ? setTaxaEntrega("Grátis") : setTaxaEntrega(taxaEntrega)
    const valorTotal = Cookies.get('vlT');
    if (taxaEntrega) {
      if (parseFloat(taxaEntrega.replaceAll(",", ".")) > 0 && entrega === "0") {
        setValorTotalCarrinho(toReal(parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(taxaEntrega.replaceAll(".", "").replaceAll(",", '.'))))
      } else {
        setButtonEnabled(false);
        setValorTotalCarrinho(valorTotal)
      }
    } else {
      setValorTotalCarrinho(valorTotal)
    }

    const url = Cookies.get('UBs');
    setUrlBase(url);

    handleCloseLoading();
  }, []);

  const handleTaxa = (taxa) => {
    if (!taxa) {
      return <>-</>
    }
    if (taxa === "-") {
      return <>-</>
    }
    if (taxa === "Grátis") {
      return <>Grátis</>
    }
    if (taxa === "combinar") {
      return <>A Combinar</>
    }
    return <>R$ {taxa}</>
  }
  const getIconForPaymentMethod = (paymentMethod) => {
    if (!paymentMethod) {
      return <PaymentsOutlined style={{ color: '#464545' }} />;
    }
    if (paymentMethod.includes("Dinheiro")) {
      return <PaymentsOutlined style={{ color: '#464545' }} />;
    }
    switch (paymentMethod) {
      case 'PIX':
        return <PixOutlined style={{ color: '#464545' }} />;
      case 'MASTERCARD':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'VISA':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'ELO':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'AMERICAN EXPRESS':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'BANRI COMPRAS':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'VERDCARD':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'HIPERCARD':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'DINNERS CLUB':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'AURA':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'CREDICARD':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'SOROCRED':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'PAN':
        return <CreditCardOutlined style={{ color: '#464545' }} />;
      case 'ALELO':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'SODEXO':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'TICKET':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'VR BENEFICIOS':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'FLASH':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'GREENCARD':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'VB':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'IFOOD ALIMENTAÇÃO':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      case 'LINK DE PAGAMENTO':
        return <LinkIcon style={{ color: '#464545' }} />;
      case 'PONTOS':
        return <CardMembershipOutlined style={{ color: '#464545' }} />;
      default:
        return <PaymentsOutlined style={{ color: '#464545' }} />;
    }
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <div style={{ padding: 16 }}>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            <Typography style={{ color: "#fff", fontSize: "20px" }}>Finalizar</Typography>
            <Typography style={{ position: "absolute", right: 320, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "5px 10px 5px 8px", color: "#475569" }}>
              <AccessTimeOutlined />
              <Typography style={{ marginLeft: "10px", textAlign: "left", lineHeight: "1.2" }}>
                <strong>Tempo de entrega</strong>
                <br />
                <strong style={{ fontSize: "14px", color: "#0284c7" }}>{tempoEntrega}</strong>
              </Typography>
            </Typography>

            <Typography style={{ position: "absolute", right: 165, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "5px 10px 5px 8px", color: "#475569", cursor: "pointer" }} onClick={handleImpressora}>
              {impressora ? (
                <PrintOutlined />
              ) : (
                <PrintDisabledOutlined />
              )}
              <Typography style={{ marginLeft: "10px", textAlign: "left", lineHeight: "1.2" }}>
                <strong>Impressora</strong>
                <br />
                {impressora ? (
                  <strong style={{ fontSize: "14px", color: "#16a34a" }}>Ativa</strong>
                ) : (
                  <strong style={{ fontSize: "14px", color: "#FF5733" }}>Inativa</strong>
                )}
              </Typography>
            </Typography>

            <Typography style={{ position: "absolute", right: 90, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "7px 10px 7px 10px", color: "#475569", cursor: "pointer" }} onClick={handleAudio}>
              {audio ? (
                <VolumeUpOutlined style={{ fontSize: "34px" }} />
              ) : (
                <VolumeOffOutlined style={{ fontSize: "34px" }} />
              )}
            </Typography>

            <Typography style={{ position: "absolute", right: 16, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "7px 10px 7px 10px", color: "#475569", cursor: "pointer" }} onClick={handleInicio}>
              <HomeOutlined style={{ fontSize: "34px" }} />
            </Typography>

          </Toolbar>
        </AppBar>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
          message={messageSnack}
        />
        <div style={{ height: "70px" }}></div>

        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              handleFinalizar(true);
            },
          }}
        >
          <DialogTitle>Finalizar pedido:</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Para finalizar o seu pedido, por favor digite seu nome e telefone abaixo:
            </DialogContentText>
            <TextField
              margin="dense"
              label="Nome"
              value={nomeAvulso}
              onChange={handleNome}
              placeholder="Nome"
              fullWidth
            />
            <TextField
              margin="dense"
              label="Telefone com DDD"
              value={telefoneAvulso}
              onChange={handleTelefone}
              placeholder="Telefone (só números)"
              fullWidth
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} style={{ textTransform: "capitalize", fontWeight: "700" }}>Voltar</Button>
            <Button type="submit" style={{ textTransform: "capitalize", fontWeight: "700" }}>Continuar</Button>
          </DialogActions>
        </Dialog>

        <Box sx={{ width: '100%' }}>
          <Tabs value={tipoEntrega} onChange={handleChange} variant="fullWidth" >
            <Tab label="Entrega" {...a11yProps(0)} style={{ textTransform: "capitalize", borderBottom: "1px solid #CCC" }} />
            <Tab label="Retirada" {...a11yProps(1)} style={{ textTransform: "capitalize", borderBottom: "1px solid #CCC" }} />
            <Tab label="Mesa" {...a11yProps(2)} style={{ textTransform: "capitalize", borderBottom: "1px solid #CCC" }} />
          </Tabs>
          <CustomTabPanel value={tipoEntrega} index={0}>
            <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold", marginLeft: "-22px", marginRight: "-22px" }}>
              Entregar em:
              <div style={{ height: "10px" }}></div>
              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  avatar={
                    <FormControl value={enderecoEntrega}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel value="2" checked control={<Radio />} />
                      </RadioGroup>
                    </FormControl>
                  }
                  action={
                    <Button variant="text" aria-label="settings" style={{ textTransform: "capitalize", fontWeight: "bold" }} onClick={handleEndereco}>
                      Alterar
                    </Button>
                  }
                  title="Casa"
                  titleTypographyProps={{ fontSize: "12px", fontWeight: "500", marginLeft: "-20px", color: "#999" }}
                  subheader={enderecoEntrega}
                  subheaderTypographyProps={{ fontSize: "14px", fontWeight: "bold", marginLeft: "-20px", color: "#1B1B1B" }}
                />
              </Card>
              <div style={{ height: "12px" }}></div>
              Hoje, {tempoEntrega}
              <div style={{ height: "10px" }}></div>

              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                <CardHeader
                  title="Taxa de entrega"
                  titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                  subheader={handleTaxa(taxaEntrega)}
                  subheaderTypographyProps={{ fontSize: "12px", fontWeight: "bold", color: "#1B1B1B" }}
                />
              </Card>

              <div style={{ height: "12px" }}></div>
              Forma de pagamento
              <div style={{ height: "10px" }}></div>
              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  avatar={getIconForPaymentMethod(formaPagamento.toUpperCase())}
                  action={
                    <Button variant="text" aria-label="settings" style={{ textTransform: "capitalize", fontWeight: "bold" }} onClick={handlePagamento}>
                      Alterar
                    </Button>
                  }
                  title={formaPagamento.toUpperCase()}
                  titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
                />
              </Card>
            </Typography>
          </CustomTabPanel>
          <CustomTabPanel value={tipoEntrega} index={1}>
            <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold", marginLeft: "-22px", marginRight: "-22px" }}>
              Retirar em:
              <div style={{ height: "10px" }}></div>
              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  title="Local"
                  titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                  subheader={enderecoRetirada}
                  subheaderTypographyProps={{ fontSize: "14px", fontWeight: "bold", color: "#1B1B1B" }}
                />
              </Card>
              <div style={{ height: "12px" }}></div>
              Hoje, {tempoEntrega}
              <div style={{ height: "10px" }}></div>

              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                <CardHeader
                  title="Taxa de entrega"
                  titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                  subheader="-"
                  subheaderTypographyProps={{ fontSize: "12px", fontWeight: "bold", color: "#1B1B1B" }}
                />
              </Card>

              <div style={{ height: "12px" }}></div>
              Forma de pagamento
              <div style={{ height: "10px" }}></div>
              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  avatar={getIconForPaymentMethod(formaPagamento.toUpperCase())}
                  action={
                    <Button variant="text" aria-label="settings" style={{ textTransform: "capitalize", fontWeight: "bold" }} onClick={handlePagamento}>
                      Alterar
                    </Button>
                  }
                  title={formaPagamento.toUpperCase()}
                  titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
                />
              </Card>
            </Typography>
          </CustomTabPanel>

          <CustomTabPanel value={tipoEntrega} index={2}>
            <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold", marginLeft: "-22px", marginRight: "-22px" }}>
              Entregar em:
              <div style={{ height: "10px" }}></div>
              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  action={
                    <Button variant="text" aria-label="settings" style={{ textTransform: "capitalize", fontWeight: "bold" }} onClick={handleEndereco}>
                      Alterar
                    </Button>
                  }
                  title="Mesa"
                  titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                  subheader={numeroMesa ? numeroMesa : "Não definida"}
                  subheaderTypographyProps={{ fontSize: "14px", fontWeight: "bold", color: "#1B1B1B" }}
                />
              </Card>
              <div style={{ height: "12px" }}></div>
              Hoje, {tempoEntrega}
              <div style={{ height: "10px" }}></div>

              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                <CardHeader
                  title="Taxa de entrega"
                  titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                  subheader="-"
                  subheaderTypographyProps={{ fontSize: "12px", fontWeight: "bold", color: "#1B1B1B" }}
                />
              </Card>

              <div style={{ height: "12px" }}></div>
              Forma de pagamento
              <div style={{ height: "10px" }}></div>
              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  avatar={<TableBarOutlined />}
                  title="A DEFINIR"
                  titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
                />
              </Card>
            </Typography>
          </CustomTabPanel>
        </Box>

        <div style={{ borderTop: `solid 1px rgba(${colorPrimary})`, position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "16px 16px 16px 16px" }}>
          <Typography style={{ float: "left", fontSize: "14px", fontWeight: "bold", paddingLeft: "16px" }}>
            {!isMesa ? (
              <>
                Total com entrega
              </>
            ) : (
              <>
                Total
              </>
            )}
          </Typography>
          <Typography style={{ float: "right", fontSize: "16px", fontWeight: "bold", color: `rgba(${colorPrimary})` }}>
            {valorTotalCarrinho === "0" ? (
              <>
                Resgate
              </>
            ) : (
              <>
                R$ {valorTotalCarrinho}
              </>
            )}
          </Typography>
          <div style={{ height: "35px" }}></div>
          <Button fullWidth disabled={buttonEnabled} variant="contained" size="large" style={{ textTransform: "capitalize", fontWeight: "700", color: "#fff" }} onClick={() => handleFinalizar(false)}>
            Continuar
          </Button>
        </div>
        <div style={{ height: "150px" }}></div>

      </div>
    </ThemeProvider>
  );
}

export default Finalizar;