import { Container, AppBar, Toolbar, IconButton, Typography, Avatar, List, ListItem, ListItemButton, ListItemText, TextField, InputAdornment, OutlinedInput, Button, SwipeableDrawer, Skeleton, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import * as React from 'react';
import axios from 'axios';
import '@fontsource/roboto/400.css';
import backgroundImage from "../imagens/bg-1.png";
import logoImage from "../imagens/logo.png";
import { LockOutlined, MailOutline } from '@mui/icons-material';
import LoadingComponent from '../components/LoadingComponent';
import Cookies from 'js-cookie';
import Snackbar from '@mui/material/Snackbar';

function Login({ onChange, colorPrimary, colorSecondary, colorTertiary }, props) {

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [isLogged, setIsLogged] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleSenha = (event) => {
    setSenha(event.target.value);
  };

  useEffect(() => {
    handleOpenLoading();
    const isLogged = Cookies.get("isLoggedEst");

    if (isLogged) {
      onChange("", "dashboard")
      handleCloseLoading();
      return
    }
    handleCloseLoading();
    setIsLogged(true)
  })

  const loginBase = (urlbase) => {
    handleOpenLoading();
    const post_url = urlbase + "/api/login-estabelecimento.php";
    const json = JSON.stringify({
      email: email,
      senha: senha,
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response === null || response === undefined) {
          handleSnack("A senha digitada para o e-mail é inválida, se estiver com problemas em sua senha entre em contato com nosso suporte.");
          handleCloseLoading();
        }
        else {
          if (response.resposta === "email invalido") {
            handleSnack("O e-mail digitado não é válido, verifique e tente novamente.");
            handleCloseLoading();
          } else {
            for (let user of response) {
              Cookies.set('dsEst', user.descricao, { expires: 7 });
              Cookies.set('emEst', user.email, { expires: 7 });
              Cookies.set('endEst', user.endereco, { expires: 7 });
              Cookies.set('fMsP', user.formas_pagamento, { expires: 7 });
              Cookies.set('hREst', user.horarios, { expires: 7 });
              Cookies.set('iEst', user.idestabelecimentos, { expires: 7 });
              Cookies.set('nmEst', user.nome, { expires: 7 });
              Cookies.set('txEst', user.taxa_entrega, { expires: 7 });
              Cookies.set('tEst', user.telefones, { expires: 7 });
              Cookies.set('tsEst', user.tipo_segmento, { expires: 7 });
              Cookies.set('UBs', user.url_base, { expires: 7 });
              Cookies.set('isLoggedEst', true, { expires: 7 });
              getConfig(user.url_base);
              onChange("", "dashboard", true)
            }
            handleCloseLoading();
          }
        }

      })
      .catch(error => {
        handleSnack("Não foi possivel entrar em sua conta, tente novamente mais tarde.");
        handleCloseLoading();
      });
  }

  const getConfig = (url) => {
    axios.get(`${url}/api/get-conf.php`, {})
      .then(response => {
        const arrConf = response.data.split(`\n`)
        for (const variable of arrConf) {
          if (variable.includes("mainName")) {
            const mainName = variable.replace("const mainName = ", "").replaceAll("\"", "")
            Cookies.set('Mn', mainName, { expires: 7 });
          }
          if (variable.includes("timeDelivery")) {
            const timeDelivery = variable.replace("const timeDelivery = ", "").replaceAll("\"", "")
            Cookies.set('tD', timeDelivery, { expires: 7 });
          }
          if (variable.includes("mainId")) {
            const mainId = variable.replace("const mainId = ", "")
            Cookies.set('Md', mainId, { expires: 7 });
          }
          if (variable.includes("urlBase")) {
            const urlBase = variable.replace("const urlBase = ", "").replaceAll("\"", "")
            const favicon = document.querySelector('link[rel="icon"]');
            favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;
            Cookies.set('UBs', urlBase, { expires: 7 });
          }
          if (variable.includes("urlMessage")) {
            const urlMessage = variable.replace("const urlMessage = ", "").replaceAll("\"", "").replaceAll(" // wa.me = whatsapp ------ m.me = messenger", "")
            Cookies.set('UMs', urlMessage, { expires: 7 });
          }
          if (variable.includes("valorMinimo")) {
            const valorMinimo = variable.replace("const valorMinimo = ", "").replaceAll("\"", "")
            Cookies.set('vlMnm', valorMinimo, { expires: 7 });
          }
          if (variable.includes("enableStore")) {
            const enableStore = variable.replace("const enableStore = ", "")
            Cookies.set('eNbS', enableStore === "true", { expires: 7 });
          }
          if (variable.includes("enableForBuy")) {
            const enableStore = variable.replace("const enableForBuy = ", "")
            Cookies.set('eNbB', enableStore === "true", { expires: 7 });
          }
          if (variable.includes("onlyMenu")) {
            const enableStore = variable.replace("const onlyMenu = ", "")
            Cookies.set('eNbM', enableStore === "true", { expires: 7 });
          }
          if (variable.includes("const typeStore")) {
            const tipoLoja = variable.replace("const typeStore = ", "").replaceAll("\"", "")
            Cookies.set('tpLj', tipoLoja, { expires: 7 });
          }
        }
      })
      .catch(error => { 
        handleSnack("Não foi possivel resgatar as informações de sua loja, verifique sua conexão e tente novamente.")
        console.log(error)
      });
  }

  const handleLogin = () => {

    handleOpenLoading();

    if (!email || !senha) {
      handleSnack("Por favor, preencha todos os campos para realizar o login.")
      handleCloseLoading();
      return
    }

    const post_url = "https://mely.online/api/login-pdv.php";
    const json = JSON.stringify({
      email: email,
      senha: senha,
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data

        if (response === null || response === undefined) {
          handleSnack("A senha digitada para o e-mail é inválida, se estiver com problemas em sua senha entre em contato com nosso suporte.");
          handleCloseLoading();
        }
        else {
          if (response.resposta === "email invalido") {
            handleSnack("O e-mail digitado não é válido, verifique e tente novamente.");
            handleCloseLoading();
          } else if (response.resposta === "nao possui") {
            handleSnack("O e-mail digitado não possui o plano PDV, verifique e tente novamente.");
            handleCloseLoading();
          } else {
            for (let user of response) {
              loginBase(user.URL_CT)
            }
          }
        }
      })
      .catch(error => {
        handleSnack("Não foi possivel entrar em sua conta, tente novamente mais tarde.");
      });
  }

  return (
    <>
      {isLogged && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
          }}>

          <LoadingComponent open={loading} />

          <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
        />

          <Box
            sx={{
              backgroundColor: 'white',
              padding: '30px',
              borderRadius: '8px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
              textAlign: 'center',
            }}
          >
            <div style={{ height: 10 }}></div>

            <Typography style={{ textAlign: "center", fontSize: "22px" }}>
              <img src={logoImage} /><br />
              <div style={{ height: 10 }}></div>
              PDV MELY - LOGIN
            </Typography>

            <div style={{ height: 20 }}></div>

            <TextField
              fullWidth
              hiddenLabel
              value={email}
              onChange={handleEmail}
              variant="outlined"
              size="large"
              label="E-mail"
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutline />
                  </InputAdornment>
                ),
              }}
            />

            <div style={{ height: 20, }}></div>

            <TextField
              fullWidth
              hiddenLabel
              value={senha}
              onChange={handleSenha}
              variant="outlined"
              size="large"
              label="Senha"
              type="password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
              }}
            />

            <div style={{ height: 40 }}></div>

            <Button variant="contained" size="large" fullWidth aria-label="settings" style={{ textTransform: "capitalize", fontWeight: "bold", color: "white", minWidth: "400px" }} onClick={handleLogin}>
              Login
            </Button>

            <div style={{ height: 30 }}></div>

            <div style={{ position: "fixed", bottom: 0, left: 10, fontSize: 16, color: "white" }}>
              © v2.0.1.0
            </div>

          </Box>
        </Box>
      )}
    </>
  );
}

export default Login;