import React, { useEffect, useState } from 'react';
import '../components/SplashScreen.css';
import logoImage from "../imagens/logo.png";
import { Typography } from '@mui/material';

const PulseFade = () => {
  const [fadeOut, setFadeOut] = useState(false);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
      const hide = setTimeout(() => {
        setHide(true);
      }, 500);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {!hide && (
        <div style={{ position: "fixed", top: 0, right: 0, left: 0, bottom: 0, background: "#cc2900", zIndex: 10001 }} className={`pulse ${fadeOut ? 'fade-out' : ''}`}>
          <Typography style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", margin: "0" }}>
            <Typography style={{ textAlign: "center", fontSize: "130px", fontWeight: "bold", color: "#fff", minWidth: "300px", fontFamily: "calibri" }}>

              <div style={{ height: "10px" }}></div>

              MELY

              <div style={{ height: "20px" }}></div>

            </Typography>
          </Typography>
        </div>
      )}
    </>
  );
};

export default PulseFade;
