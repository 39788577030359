import { Container, AppBar, Toolbar, Typography, IconButton, Button, Box, Tabs, Tab, Card, CardHeader, FormControl, RadioGroup, FormControlLabel, Radio, Skeleton, TextField, Divider } from '@mui/material';
import { AccessTimeOutlined, ArrowBackOutlined, HomeOutlined, LocalPrintshopOutlined, PrintDisabledOutlined, PrintOutlined, VolumeOffOutlined, VolumeUpOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CustomTabPanel, a11yProps, toReal, createNewTheme } from '../functions/utils';
import * as React from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import LoadingComponent from '../components/LoadingComponent';
import Snackbar from '@mui/material/Snackbar';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';

function Enderecos() {

  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const page = state ? state.page : 'home';
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [isLogged, setIsLogged] = useState(false);
  const [audio, setAudio] = React.useState(true);
  const [impressora, setImpressora] = React.useState(true);
  const [enderecoRetirada, setEnderecoRetirada] = useState("");
  const [enderecoEntrega, setEnderecoEntrega] = useState("");
  const [nomeEnderecoEntrega, setNomeEnderecoEntrega] = useState("");
  const [tipoEntrega, setTipoEntrega] = useState(0);
  const [disableEntrega, setDisableEntrega] = useState(0);
  const [disableRetirada, setDisableRetirada] = useState(0);
  const [tempoEntrega, setTempoEntrega] = useState("");
  const [valorEntrega, setValorEntrega] = useState("-");
  const [endereco, setEndereco] = useState("");
  const [nomeCliente, setNomeCliente] = useState("");
  const [numeroMesa, setNumeroMesa] = useState();
  const [telefoneCliente, setTelefoneCliente] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msgSnack, setMsgSnack] = React.useState(false);

  const navigate = useNavigate();

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleSelecionarEndereco = () => {
    if (telefoneCliente.length < 11) {
      handleSnack("Você precisa digitar um telefone válido, com apenas números. Por exemplo.: 11912341234");
      return
    }
    Cookies.set('tE', 0, { expires: 7 });
    Cookies.set('tUsr', formatPhoneNumber(telefoneCliente), { expires: 7 });
    navigate("/selecionar-endereco", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
  };

  const handleSelecionarTelefone = () => {
    setEnderecoEntrega("")
    Cookies.remove("eEA");
    Cookies.remove("tUsr");
  };

  const handleBack = () => {
    navigate("/carrinho", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
  };

  const handleAudio = (page) => {
    Cookies.set('Aud', !audio, { expires: 7 });
    setAudio(!audio)
  }

  const handleImpressora = (page) => {
    Cookies.set('IAt', !impressora, { expires: 7 });
    setImpressora(!impressora)
  }

  const handleInicio = () => {
    navigate("/", { replace: true, state: { page: "pedidos" } })
  };

  const handlePagamentos = () => {
    if (enderecoEntrega) {
      Cookies.set('eEA', enderecoEntrega, { expires: 7 })
    }
    Cookies.set('tE', tipoEntrega, { expires: 7 });

    if (tipoEntrega === 0) {
      Cookies.remove('nM');
      Cookies.set('nmUsr', nomeCliente, { expires: 7 });
    }
    if (tipoEntrega === 1) {
      Cookies.remove('nM');
      Cookies.set('nmUsr', nomeCliente, { expires: 7 });
      Cookies.set('tUsr', formatPhoneNumber(telefoneCliente), { expires: 7 });
    }
    if (tipoEntrega === 2) {
      Cookies.set('nM', numeroMesa, { expires: 7 });
      Cookies.set('nmUsr', nomeCliente, { expires: 7 });
      Cookies.set('fmP', "definir", { expires: 7 });
      navigate("/finalizar", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
      return
    }
    let bloquearCarrinho = Cookies.get('bCrt');
    if (bloquearCarrinho) {
      Cookies.set('fmP', "pontos", { expires: 7 });
      navigate("/finalizar", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
    } else {
      navigate("/pagamentos", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
    }
  };

  const handleChange = (event, newValue) => {
    setTipoEntrega(newValue);
    const valorTotal = Cookies.get('vlT');
    setValorTotalCarrinho(valorTotal);
    if (newValue === 0) {
      validaEndereco()
    }
    if (newValue === 1) {
      validaRetirada(telefoneCliente, nomeCliente)
    }
    if (newValue === 2) {
      validaMesa(numeroMesa)
    }
  };

  useEffect(() => {
    handleOpenLoading();

    const audio = Cookies.get("Aud") !== "false";
    const impressora = Cookies.get("IAt") !== "false";
    const timeDelivery = Cookies.get('tD');
    const arrTime = timeDelivery.split("(")
    setTempoEntrega(arrTime[1].replaceAll(")", ""))
    setAudio(audio)
    setImpressora(impressora)

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;

    if (colorPrimary) {
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }

    getInfos();
    validaEndereco();
    const valorTotal = Cookies.get('vlT');
    setValorTotalCarrinho(valorTotal)
    const entrega = Cookies.get('tE');

    if (entrega) {
      setTipoEntrega(parseInt(entrega));
      setButtonEnabled(false)
    }
    let nomeCliente
    switch (entrega) {
      case "0":
        const enderecoEntregaAtual = Cookies.get('eEA');
        enderecoEntregaAtual ? setButtonEnabled(false) : setButtonEnabled(true)
        const telefoneCliente = Cookies.get('tUsr');
        telefoneCliente ? setTelefoneCliente(telefoneCliente.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "")) : setTelefoneCliente("")
        break;
      case "1":
        nomeCliente = Cookies.get('nmUsr');
        nomeCliente ? setButtonEnabled(false) : setButtonEnabled(true)
        setNomeCliente(nomeCliente)
        break;
      case "2":
        let numeroMesa = Cookies.get('nM');
        setNumeroMesa(numeroMesa)
        validaMesa(numeroMesa)
        break;
    }

    const entregasAtivas = Cookies.get('tD');
    entregasAtivas.includes("Entrega") ? setDisableEntrega(false) : setDisableEntrega(true);
    entregasAtivas.includes("Retirada") ? setDisableRetirada(false) : setDisableRetirada(true);
    if (!entregasAtivas.includes("Entrega")) {
      setButtonEnabled(false)
      setTipoEntrega(1)
    }
  }, []);

  const getInfos = () => {
    const mainId = Cookies.get('Md');
    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/estabelecimentos.php`, {
      idestabelecimentos: mainId
    })
      .then(response => {
        const informacoes = response.data[0]
        const enderecoArr = informacoes.endereco.split("___")
        setEnderecoRetirada(enderecoArr[0])
        Cookies.set('eR', enderecoArr[0], { expires: 7 });
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const validaEndereco = () => {
    handleOpenLoading()
    const isLogged = Cookies.get('isLogged');
    setIsLogged(isLogged)
    const timeDelivery = Cookies.get('tD');
    const arrTime = timeDelivery.split("(")
    setTempoEntrega(arrTime[1].replaceAll(")", ""))

    const enderecoEntregaAtual = Cookies.get('eEA');
    enderecoEntregaAtual ? setButtonEnabled(false) : setButtonEnabled(true)
    setEnderecoEntrega(enderecoEntregaAtual)
    const nomeEndereco = Cookies.get('nmEA');
    setNomeEnderecoEntrega(nomeEndereco)
    if (enderecoEntregaAtual) {
      const mainId = Cookies.get('Md');
      const urlBase = Cookies.get('UBs');
      axios.post(`${urlBase}/retornar-taxa.php`, {
        idestabelecimentos: mainId,
        endereco: enderecoEntregaAtual
      })
        .then(response => {
          response = response.data
          if (!response) {
            setButtonEnabled(true)
            handleSnack('Algo deu errado com esse endereço, por favor verifique sua conexão e tente novamente.')
            return
          }

          let bloquearCarrinho = Cookies.get('bCrt');
          if (bloquearCarrinho) {
            validaNome(nomeCliente)
            Cookies.set('txE', "0,00", { expires: 7 });
            setValorEntrega("Grátis")
          } else if (response.resposta == "combinar") {
            validaNome(nomeCliente)
            Cookies.set('txE', "combinar", { expires: 7 });
            setValorTotalCarrinho(Cookies.get('vlT'))
            setValorEntrega("A Combinar")
          } else if (response.resposta == "0,00") {
            setValorTotalCarrinho(Cookies.get('vlT'))
            validaNome(nomeCliente)
            Cookies.set('txE', "0,00", { expires: 7 });
            setValorEntrega("Grátis")
          } else if (response.resposta.includes("endereço invalido")) {
            setButtonEnabled(true)
            handleSnack('Algo deu errado com esse endereço, por favor verifique sua conexão e tente novamente.')
          } else if (response.resposta.includes("falha consultar")) {
            setButtonEnabled(true)
            handleSnack('Algo deu errado com esse endereço, por favor verifique sua conexão e tente novamente.')
          } else if (response.resposta.includes("distancia ultrapassa")) {
            setButtonEnabled(true)
            Cookies.remove('txE')
            handleSnack('Desculpe, o estabelecimento não trabalha com entregas no endereço indicado.')
          } else if (parseFloat(response.resposta.replaceAll(",", ".")) > 0) {
            setValorTotalCarrinho(toReal(parseFloat(Cookies.get('vlT').replaceAll(".", "").replaceAll(",", '.')) + parseFloat(response.resposta.replaceAll(".", "").replaceAll(",", '.'))))
            validaNome(nomeCliente)
            setValorEntrega(`R$ ${response.resposta}`)
            Cookies.set('txE', response.resposta, { expires: 7 });
          } else {
            setButtonEnabled(true)
            handleSnack('Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.')
          }
          validaNome(nomeCliente);
          handleCloseLoading();
        })
        .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
    } else {
      handleCloseLoading();
    }
  }

  const validaNome = (nome) => {
    console.log(nome)
    !nome ? setButtonEnabled(true) : setButtonEnabled(false)
  }

  const validaMesa = (mesa) => {
    parseInt(mesa) > 0 ? setButtonEnabled(false) : setButtonEnabled(true)
  };

  const validaRetirada = (telefoneCliente, nomeCliente) => {
    nomeCliente ? setButtonEnabled(false) : setButtonEnabled(true)
  };

  const handleNumeroMesa = (event) => {
    validaMesa(event.target.value)
    setNumeroMesa(event.target.value);
  };

  const handleNomeCliente = (event) => {
    if (tipoEntrega === 0) {
      if (valorEntrega !== "-" && valorEntrega) {
        validaNome(event.target.value);
      }
    } else {
      validaRetirada(telefoneCliente, event.target.value)
    }
    setNomeCliente(event.target.value);
  };

  const handleTelefoneCliente = (event) => {
    if (tipoEntrega === 1) {
      validaRetirada(event.target.value, nomeCliente)
    }
    setTelefoneCliente(event.target.value);
  };

  const formatPhoneNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.replace(/\D/g, '');
    return `(${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(2, 7)}-${phoneNumber.substring(7, 11)}`;
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <div style={{ padding: 16 }}>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            <Typography style={{ color: "#fff", fontSize: "20px" }}>Opções de entrega</Typography>
            <Typography style={{ position: "absolute", right: 320, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "5px 10px 5px 8px", color: "#475569" }}>
              <AccessTimeOutlined />
              <Typography style={{ marginLeft: "10px", textAlign: "left", lineHeight: "1.2" }}>
                <strong>Tempo de entrega</strong>
                <br />
                <strong style={{ fontSize: "14px", color: "#0284c7" }}>{tempoEntrega}</strong>
              </Typography>
            </Typography>

            <Typography style={{ position: "absolute", right: 165, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "5px 10px 5px 8px", color: "#475569", cursor: "pointer" }} onClick={handleImpressora}>
              {impressora ? (
                <PrintOutlined />
              ) : (
                <PrintDisabledOutlined />
              )}
              <Typography style={{ marginLeft: "10px", textAlign: "left", lineHeight: "1.2" }}>
                <strong>Impressora</strong>
                <br />
                {impressora ? (
                  <strong style={{ fontSize: "14px", color: "#16a34a" }}>Ativa</strong>
                ) : (
                  <strong style={{ fontSize: "14px", color: "#FF5733" }}>Inativa</strong>
                )}
              </Typography>
            </Typography>

            <Typography style={{ position: "absolute", right: 90, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "7px 10px 7px 10px", color: "#475569", cursor: "pointer" }} onClick={handleAudio}>
              {audio ? (
                <VolumeUpOutlined style={{ fontSize: "34px" }} />
              ) : (
                <VolumeOffOutlined style={{ fontSize: "34px" }} />
              )}
            </Typography>

            <Typography style={{ position: "absolute", right: 16, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "7px 10px 7px 10px", color: "#475569", cursor: "pointer" }} onClick={handleInicio}>
              <HomeOutlined style={{ fontSize: "34px" }} />
            </Typography>
          </Toolbar>
        </AppBar>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
        />

        <div style={{ height: "70px" }}></div>

        <Box sx={{ width: '100%' }}>
          <Tabs value={tipoEntrega} onChange={handleChange} variant="fullWidth" >
            <Tab label="Entrega" disabled={disableEntrega} {...a11yProps(0)} style={{ textTransform: "capitalize", borderBottom: "1px solid #CCC" }} />
            <Tab label="Retirada" disabled={disableRetirada} {...a11yProps(1)} style={{ textTransform: "capitalize", borderBottom: "1px solid #CCC" }} />
            <Tab label="Mesa" {...a11yProps(2)} style={{ textTransform: "capitalize", borderBottom: "1px solid #CCC" }} />
          </Tabs>
          <CustomTabPanel value={tipoEntrega} index={0}>
            <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold", marginLeft: "-22px", marginRight: "-22px" }}>
              {enderecoEntrega ? (
                <>Entregar em:</>
              ) : (
                <>Informe o telefone do cliente para buscar os endereços cadastrados</>
              )}
              <div style={{ height: "10px" }}></div>
              {enderecoEntrega ? (
                <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    avatar={
                      <FormControl value={endereco}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel checked value="2" control={<Radio />} />
                        </RadioGroup>
                      </FormControl>
                    }
                    action={
                      <>
                        <Button variant="text" aria-label="settings" style={{ textTransform: "capitalize", fontWeight: "bold" }} onClick={handleSelecionarEndereco}>
                          Alterar Endereço
                        </Button><br />
                        <Button variant="text" aria-label="settings" style={{ textTransform: "capitalize", fontWeight: "bold" }} onClick={handleSelecionarTelefone}>
                          Alterar Telefone
                        </Button>
                      </>
                    }
                    title={nomeEnderecoEntrega}
                    titleTypographyProps={{ fontSize: "12px", fontWeight: "500", marginLeft: "-20px", color: "#999" }}
                    subheader={enderecoEntrega}
                    subheaderTypographyProps={{ fontSize: "14px", fontWeight: "bold", marginLeft: "-20px", color: "#1B1B1B" }}
                  />
                </Card>
              ) : (
                <>
                  <div style={{ height: "10px" }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    value={telefoneCliente}
                    onChange={handleTelefoneCliente}
                    variant="outlined"
                    size="large"
                    label="Telefone do cliente"
                    type="text"
                  />
                  <div style={{ height: "10px" }}></div>
                  <Button variant="outlined" fullWidth aria-label="settings" style={{ textTransform: "capitalize", fontWeight: "bold" }} onClick={handleSelecionarEndereco}>
                    Buscar
                  </Button>
                </>
              )}

              {enderecoEntrega ? (
                <>
                  <div style={{ height: "12px" }}></div>
                  Hoje, {tempoEntrega}
                  <div style={{ height: "10px" }}></div>

                  <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                    <CardHeader
                      title="Taxa de entrega"
                      titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                      subheader={valorEntrega}
                      subheaderTypographyProps={{ fontSize: "12px", fontWeight: "bold", color: "#1B1B1B" }}
                    />
                  </Card>
                </>
              ) : (
                <></>
              )}

              {enderecoEntrega ? (
                <>
                  <div style={{ height: "12px" }}></div>
                  Dados do cliente:
                  <div style={{ height: "10px" }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    value={nomeCliente}
                    onChange={handleNomeCliente}
                    variant="outlined"
                    size="large"
                    label="Nome do cliente"
                    type="text"
                  />
                </>
              ) : (
                <></>
              )}

            </Typography>
          </CustomTabPanel>
          <CustomTabPanel value={tipoEntrega} index={1}>

            <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold", marginLeft: "-22px", marginRight: "-22px" }}>

              Informe os dados abaixo:
              <div style={{ height: "20px" }}></div>
              <TextField
                fullWidth
                hiddenLabel
                value={nomeCliente}
                onChange={handleNomeCliente}
                variant="outlined"
                size="large"
                label="Nome do cliente"
                type="text"
              />
              <div style={{ height: "20px" }}></div>
              <TextField
                fullWidth
                hiddenLabel
                value={telefoneCliente}
                onChange={handleTelefoneCliente}
                variant="outlined"
                size="large"
                label="Telefone do cliente"
                type="text"
              />
              <div style={{ height: "30px" }}></div>
              <Divider />
              <div style={{ height: "20px" }}></div>

              Retirar em:
              <div style={{ height: "10px" }}></div>
              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  title="Local"
                  titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                  subheader={enderecoRetirada ? (
                    enderecoRetirada
                  ) : (
                    <Skeleton animation="wave" height={30} width="100%" />
                  )}
                  subheaderTypographyProps={{ fontSize: "14px", fontWeight: "bold", color: "#1B1B1B" }}
                />
              </Card>

            </Typography>
          </CustomTabPanel>

          <CustomTabPanel value={tipoEntrega} index={2}>
            <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold", marginLeft: "-22px", marginRight: "-22px" }}>
              Informe os dados abaixo:
              <div style={{ height: "20px" }}></div>
              <TextField
                fullWidth
                hiddenLabel
                value={numeroMesa}
                onChange={handleNumeroMesa}
                variant="outlined"
                size="large"
                label="Número da mesa"
                type="text"
              />

            </Typography>
          </CustomTabPanel>
        </Box>

        <div style={{ borderTop: `solid 1px rgba(${colorPrimary})`, position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "16px 16px 16px 16px" }}>
          <Typography style={{ float: "left", fontSize: "14px", fontWeight: "bold", paddingLeft: "16px" }}>
            Total com entrega
          </Typography>
          <Typography style={{ float: "right", fontSize: "16px", fontWeight: "bold", color: `rgba(${colorPrimary})` }}>
            {valorTotalCarrinho === "0" ? (
              <>
                Resgate
              </>
            ) : (
              <>
                R$ {valorTotalCarrinho}
              </>
            )}
          </Typography>
          <div style={{ height: "35px" }}></div>
          <Button fullWidth disabled={buttonEnabled} variant="contained" size="large" style={{ textTransform: "capitalize", fontWeight: "700", color: "#fff" }} onClick={handlePagamentos}>
            Continuar
          </Button>
        </div>
        <div style={{ height: "100px" }}></div>

      </div>
    </ThemeProvider>
  );
}

export default Enderecos;