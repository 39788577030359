import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Badge, Divider, IconButton, Toolbar, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { AccessTimeOutlined, AssessmentOutlined, BurstModeOutlined, ColorLensOutlined, DeliveryDiningOutlined, ExpandMore, HistoryOutlined, Inventory2Outlined, LocalPrintshopOutlined, LogoutOutlined, LoyaltyOutlined, MenuBookOutlined, PeopleAltOutlined, PersonOutlineOutlined, PrintDisabled, PrintDisabledOutlined, PrintOutlined, QrCodeOutlined, RoomServiceOutlined, TableBarOutlined, VolumeOffOutlined, VolumeUpOutlined } from '@mui/icons-material';
import Cookies from 'js-cookie';
import axios from 'axios';
import notificationSound from '../functions/new-order.mp3';

const SideMenu = ({ onChange, update, colorPrimary, currentPage }) => {
    const [open, setOpen] = React.useState(false);
    const [urlBase, setUrlBase] = React.useState("");
    const [audio, setAudio] = React.useState(true);
    const [impressora, setImpressora] = React.useState(true);
    const [deliveryNovos, setDeliveryNovos] = React.useState();
    const [mesasNovos, setMesasNovos] = React.useState();
    const [retiradaNovos, setRetiradaNovos] = React.useState();
    const [qtdNot, setQtdNot] = React.useState();
    const [tempoEntrega, setTempoEntrega] = React.useState("");
    const audioRef = React.useRef(null);

    const toggleDrawer = (isOpen) => () => {
        setOpen(isOpen);
    };

    const handlePage = (page) => {
        setOpen(false);
        onChange("", page);
    }

    const handleAudio = (page) => {
        Cookies.set('Aud', !audio, { expires: 7 });
        setAudio(!audio)
    }

    const handleImpressora = (page) => {
        Cookies.set('IAt', !impressora, { expires: 7 });
        setImpressora(!impressora)
    }

    useEffect(() => {
        const urlbase = Cookies.get("UBs");
        (!urlbase) ? setUrlBase("") : setUrlBase(urlbase.replaceAll("/api", ""));
        const audio = Cookies.get("Aud") !== "false";
        const impressora = Cookies.get("IAt") !== "false";
        const timeDelivery = Cookies.get('tD');
        const arrTime = timeDelivery.split("(")
        setTempoEntrega(arrTime[1].replaceAll(")", ""))
        setAudio(audio)
        setImpressora(impressora)
        updateNot();
    }, [update]);

    const updateNot = () => {
        let count = 0
        const deliveryNovos = Cookies.get("nTDn") === "true";
        const mesasNovos = Cookies.get("nTMs") === "true";
        const retiradaNovos = Cookies.get("nTRn") === "true";
        deliveryNovos ? count += 1 : console.log("-- SEM DELIVERY NOVO --")
        mesasNovos ? count += 1 : console.log("-- SEM MESA NOVA --")
        retiradaNovos ? count += 1 : console.log("-- SEM RETIRADA NOVA --")
        setQtdNot(count)
        setDeliveryNovos(deliveryNovos)
        setMesasNovos(mesasNovos)
        setRetiradaNovos(retiradaNovos)
    }

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#fff" }} onClick={toggleDrawer(true)}>
                        <Badge
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }} badgeContent={qtdNot} color="secondary">
                            <MenuIcon />
                        </Badge>
                    </IconButton>
                    <Typography style={{ color: "#fff", fontSize: "20px" }}>{currentPage}</Typography>
                    <Typography style={{ position: "absolute", right: 245, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "5px 10px 5px 8px", color: "#475569" }}>
                        <AccessTimeOutlined />
                        <Typography style={{ marginLeft: "10px", textAlign: "left", lineHeight: "1.2" }}>
                            <strong>Tempo de entrega</strong>
                            <br />
                            <strong style={{ fontSize: "14px", color: "#0284c7" }}>{tempoEntrega}</strong>
                        </Typography>
                    </Typography>

                    <Typography style={{ position: "absolute", right: 90, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "5px 10px 5px 8px", color: "#475569", cursor: "pointer" }} onClick={handleImpressora}>
                        {impressora ? (
                            <PrintOutlined />
                        ) : (
                            <PrintDisabledOutlined />
                        )}
                        <Typography style={{ marginLeft: "10px", textAlign: "left", lineHeight: "1.2" }}>
                            <strong>Impressora</strong>
                            <br />
                            {impressora ? (
                                <strong style={{ fontSize: "14px", color: "#16a34a" }}>Ativa</strong>
                            ) : (
                                <strong style={{ fontSize: "14px", color: "#FF5733" }}>Inativa</strong>
                            )}
                        </Typography>
                    </Typography>

                    <Typography style={{ position: "absolute", right: 16, display: "flex", alignItems: "center", background: "#f8fafc", border: "solid 1px #cbd5e1", borderRadius: "6px", padding: "7px 10px 7px 10px", color: "#475569", cursor: "pointer" }} onClick={handleAudio}>
                        {audio ? (
                            <VolumeUpOutlined style={{ fontSize: "34px" }} />
                        ) : (
                            <VolumeOffOutlined style={{ fontSize: "34px" }} />
                        )}
                        <audio ref={audioRef}>
                            <source src={notificationSound} type="audio/mpeg" />
                        </audio>
                    </Typography>

                </Toolbar>
            </AppBar>

            <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
                <List style={{ padding: 20 }}>
                    <img src={`${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`} style={{ width: "150px", marginLeft: "30px" }} />
                    <div style={{ height: 15 }}></div>
                    <ListItem button onClick={() => handlePage("entrega")}>
                        {deliveryNovos ? (
                            <Badge variant='dot'
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }} badgeContent={1} color="secondary">
                                <DeliveryDiningOutlined style={{ marginRight: 14 }} />
                            </Badge>
                        ) : (<DeliveryDiningOutlined style={{ marginRight: 14 }} />)}
                        <ListItemText primary="Pedidos entrega" />
                    </ListItem>
                    <ListItem button onClick={() => handlePage("mesa")}>
                        {mesasNovos ? (
                            <Badge variant='dot'
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }} badgeContent={1} color="secondary">
                                <TableBarOutlined style={{ marginRight: 14 }} />
                            </Badge>
                        ) : (<TableBarOutlined style={{ marginRight: 14 }} />)}

                        <ListItemText primary="Pedidos mesa" />
                    </ListItem>
                    <ListItem button onClick={() => handlePage("retirada")}>
                        {retiradaNovos ? (
                            <Badge variant='dot'
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }} badgeContent={1} color="secondary">
                                <RoomServiceOutlined style={{ marginRight: 14 }} />
                            </Badge>
                        ) : (<RoomServiceOutlined style={{ marginRight: 14 }} />)}

                        <ListItemText primary="Pedidos retiradas" />
                    </ListItem>
                    <ListItem button onClick={() => handlePage("historico")}>
                        <HistoryOutlined style={{ marginRight: 14 }} />
                        <ListItemText primary="Historico de pedidos" />
                    </ListItem>
                    <ListItem button onClick={() => handlePage("dashboard")}>
                        <AssessmentOutlined style={{ marginRight: 14 }} />
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <Divider />
                    {/* <Accordion elevation={'none'} style={{ margin: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Gestão do sistema</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0, marginTop: "-10px" }}>
                            <ListItem button onClick={() => handlePage("cardapio")}>
                                <PeopleAltOutlined style={{ marginRight: 14 }} />
                                <ListItemText primary="Gestão de clientes" />
                            </ListItem>
                            <ListItem button onClick={() => handlePage("cardapio")}>
                                <MenuBookOutlined style={{ marginRight: 14 }} />
                                <ListItemText primary="Gestão do cardápio" />
                            </ListItem>
                            <ListItem button onClick={() => handlePage("cardapio")}>
                                <Inventory2Outlined style={{ marginRight: 14 }} />
                                <ListItemText primary="Gestão de estoque" />
                            </ListItem>
                            <ListItem button onClick={() => handlePage("cardapio")}>
                                <QrCodeOutlined style={{ marginRight: 14 }} />
                                <ListItemText primary="QR Code" />
                            </ListItem>
                            <ListItem button onClick={() => handlePage("cardapio")}>
                                <LoyaltyOutlined style={{ marginRight: 14 }} />
                                <ListItemText primary="Fidelidade" />
                            </ListItem>
                            <ListItem button onClick={() => handlePage("cardapio")}>
                                <HistoryOutlined style={{ marginRight: 14 }} />
                                <ListItemText primary="Historico de pedidos" />
                            </ListItem>
                        </AccordionDetails>
                    </Accordion> */}
                    <Divider />
                    {/* <Accordion elevation={'none'} style={{ margin: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Configurações</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0, marginTop: "-10px" }}>
                            <ListItem button onClick={() => handlePage("cardapio")}>
                                <PersonOutlineOutlined style={{ marginRight: 14 }} />
                                <ListItemText primary="Minha conta" />
                            </ListItem>
                            <ListItem button onClick={() => handlePage("cardapio")}>
                                <ColorLensOutlined style={{ marginRight: 14 }} />
                                <ListItemText primary="Ajustar cores" />
                            </ListItem>
                            <ListItem button onClick={() => handlePage("cardapio")}>
                                <BurstModeOutlined style={{ marginRight: 14 }} />
                                <ListItemText primary="Banners" />
                            </ListItem>
                            <ListItem button onClick={() => handlePage("cardapio")}>
                                <LogoutOutlined style={{ marginRight: 14 }} />
                                <ListItemText primary="Sair" />
                            </ListItem>
                        </AccordionDetails>
                    </Accordion> */}
                    <ListItem button onClick={() => handlePage("login")}>
                        <LogoutOutlined style={{ marginRight: 14 }} />
                        <ListItemText primary="Sair" />
                    </ListItem>
                    <div style={{ height: "80px" }}></div>

                </List>
                <Typography style={{ position: "fixed", bottom: 0, textAlign: "center", background: "white", paddingTop: "20px", paddingLeft: "30px" }}>
                    <img src="https://mely.online/images/logo.png" style={{ width: "200px" }} /><br />

                    v2.0.1.0</Typography>
            </Drawer>
        </>
    );
};

export default SideMenu;
